.footer-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 10px 0 auto;
}

.footer-logo-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 40px;
}

.footer-logo {
  width: 40%;
}

.footer-logo-p {
  font-size: 15px;
  font-weight: bold;
}

.footer-link {
  font-size: 25px;
  color: #001d3d;
}

.footer-link:hover {
  cursor: pointer;
  color: #0077b6;
}

.profile-links {
  width: 40%;
  display: flex;
  justify-content: space-around;
}

/* address 1 */

.footer-address-1 {
  margin: 20px 10px;
}

.footer-address-2 {
  margin: 20px 10px;
}

.footer-address-3 {
  margin: 20px 10px;
}

.footer-h5-1 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 3px;
}

.footer-p-1 {
  font-size: 13px;
}

@media only screen and (max-width: 992px) {
  .footer-logo-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
  }

  .footer-logo {
    width: 40%;
  }

  .footer-logo-p {
    font-size: 12px;
    font-weight: bold;
    margin-top: 0px;
  }

  .footer-link {
    font-size: 25px;
    color: #001d3d;
  }

  .footer-link:hover {
    cursor: pointer;
    color: #0077b6;
  }

  .profile-links {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-top: -11px;
  }

  /* address 1 */

  .footer-address-1 {
    margin: 20px 10px;
  }

  .footer-address-2 {
    margin: 20px 10px;
  }

  .footer-address-3 {
    margin: 20px 10px;
  }

  .footer-h5-1 {
    font-size: 12px;
    font-weight: bold;
  }

  .footer-p-1 {
    font-size: 10px;
  }
}

@media only screen and (max-width: 880px) {
  .footer-logo-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0px;
  }

  .footer-logo {
    width: 50%;
  }

  .footer-logo-p {
    font-size: 12px;
    font-weight: bold;
    margin-top: 0px;
  }

  .footer-link {
    font-size: 25px;
    color: #001d3d;
  }

  .footer-link:hover {
    cursor: pointer;
    color: #0077b6;
  }

  .profile-links {
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin-top: -11px;
  }

  /* address 1 */

  .footer-address-1 {
    margin: 20px 10px;
  }

  .footer-address-2 {
    margin: 20px 10px;
  }

  .footer-address-3 {
    margin: 20px 10px;
  }

  .footer-h5-1 {
    font-size: 12px;
    font-weight: bold;
  }

  .footer-p-1 {
    font-size: 10px;
  }
}

@media only screen and (max-width: 660px) {
  .footer-logo-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0px;
  }

  .footer-logo {
    width: 50%;
  }

  .footer-logo-p {
    font-size: 12px;
    font-weight: bold;
    margin-top: 0px;
  }

  .footer-link {
    font-size: 25px;
    color: #001d3d;
  }

  .footer-link:hover {
    cursor: pointer;
    color: #0077b6;
  }

  .profile-links {
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin-top: -11px;
  }

  /* address 1 */

  .footer-address-1 {
    margin: 20px 5px;
  }

  .footer-address-2 {
    margin: 20px 5px;
  }

  .footer-address-3 {
    margin: 20px 5px;
  }

  .footer-h5-1 {
    font-size: 10px;
    font-weight: bold;
  }

  .footer-p-1 {
    font-size: 8px;
  }
}

@media only screen and (max-width: 576px) {
  .footer-logo-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0px;
  }

  .footer-logo {
    width: 30%;
  }

  .footer-logo-p {
    font-size: 14px;
    font-weight: bold;
    margin-top: 0px;
  }

  .footer-link {
    font-size: 30px;
    color: #001d3d;
  }

  .footer-link:hover {
    cursor: pointer;
    color: #0077b6;
  }

  .profile-links {
    width: 30%;
    display: flex;
    justify-content: space-between;
    margin-top: -11px;
  }

  /* address 1 */

  .footer-address-1 {
    margin: 30px 5px 20px 5px;
  }

  .footer-address-2 {
    margin: 20px 5px;
  }

  .footer-address-3 {
    margin: 20px 5px;
  }

  .footer-h5-1 {
    font-size: 14px;
    font-weight: bold;
  }

  .footer-p-1 {
    font-size: 11px;
  }
}

/* copyright and privacy section */

.footer-divider-line {
  width: 100%;
  height: 1px;
  background-color: #d4d5d5;
  margin: 30px auto;
}

.footer-copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  margin-right: 50px;
}

.footer-privacy {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  margin-right: 20px;
}

@media only screen and (max-width: 850px) {
  .footer-divider-line {
    width: 100%;
    height: 2px;
    background-color: #d4d5d5;
    margin: 20px auto;
  }

  .footer-copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    font-weight: 600;
    margin-right: 70px;
  }

  .footer-privacy {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    font-weight: 600;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .footer-divider-line {
    width: 100%;
    height: 2px;
    background-color: #d4d5d5;
    margin: 20px auto;
  }

  .footer-copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 7px;
    font-weight: 600;
    margin-right: 50px;
  }

  .footer-privacy {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 7px;
    font-weight: 600;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 410px) {
  .footer-divider-line {
    width: 100%;
    height: 2px;
    background-color: #d4d5d5;
    margin: 8px auto;
  }

  .footer-copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5px;
    font-weight: 600;
    margin-right: 30px;
  }

  .footer-privacy {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5px;
    font-weight: 600;
    margin-right: 20px;
  }
}

/* Footer heading underline  */

/* Head office */

/* 
.hover-underline-animation-1 {
  display: inline-block;
  position: relative;
  
} */
/* 
.hover-underline-animation-1 {
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-image:  linear-gradient(0deg, #138808 33%, #ffffff 33%, #ffffff 66%, #FF9933 66% );
  margin-bottom: 10px;
} */
/* 
.hover-underline-animation-1:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
} */

/* USA office */
/* 
.hover-underline-animation-2 {
  display: inline-block;
  position: relative;
}

.hover-underline-animation-2:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg, #041e42 20%, #9b1c2c 100%);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation-2:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
} */

/* Newzealand office */

/* .hover-underline-animation-3 {
  display: inline-block;
  position: relative;
}

.hover-underline-animation-3:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-image:  linear-gradient(0deg, #138808 33%, #ffffff 33%, #ffffff 66%, #FF9933 66% );
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation-3:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
} */

.hover-underline-animation-1 {
  text-underline-position: under;

  position: relative;
}
.hover-underline-animation-1::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 2.5rem;
  left: 7%;
  height: 2px;
  border-radius: 2px;
  background: linear-gradient(
    90deg,
    #138808 33%,
    #ffffff 33%,
    #ffffff 66%,
    #ff9933 66%
  );
}

.hover-underline-animation-2 {
  text-underline-position: under;

  position: relative;
}
.hover-underline-animation-2::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 2.5rem;
  left: 7%;
  height: 2px;
  border-radius: 2px;
  background: linear-gradient(
    90deg,
    #041e42 20%,
    #ffffff,
    rgba(147, 0, 0, 0.789) 100%
  );
}

.hover-underline-animation-3 {
  /* text-underline-position: under left; */
  position: relative;
}
.hover-underline-animation-3::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 2.5rem;
  left: 7%;
  height: 2px;
  border-radius: 2px;
  background: linear-gradient(90deg, red 20%, rgb(15, 15, 193), #ffffff 100%);
}

.cursor-pointer {
  cursor: pointer;
}
