.about-process-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.about-process-row-2{
  position: relative;
  margin-top: 70px !important;
  margin-bottom: 150px !important;    
}

.about-process-bg-img {
  width: 100%;
  position: relative;
  top: 19%;
}

.about-process-content-1 {
  top: 0%;
  left: 8%;
  position: absolute;
}

.about-process-span {
  width: 50px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-process-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 3rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.about-process-content-1-h2 {
  font-size: 2rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.about-process-content-1-p{
  text-align: justify;
  width: 60%;
  color: #5d5d5d;
  font-size: .8rem;
  margin: 20px 0;
}


/* flex styles */

/* flex-1 */

.flex-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 59%;
  left: 1.2%;
}


.new-flex-1 {
  display: none;
}


.flex-1-img {
  width: 30%;
  border-radius: 50%;
}

.flex-1-h6 {
  font-weight: bold;
}

.flex-1-p {
  font-size: 12px;
  font-weight: 500;
  color: #5d5d5d;
}

/* flex-2 */

.flex-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 80%;
  position: absolute;
  left: 38%;
}

.flex-2-img {
  border-radius: 50%;
  width: 30%;
}


/* flex-3 */


.flex-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: -5%;
  position: absolute;
  right: 11%;
}

.new-flex-3 {
  display: none;
}

.flex-3-img {
  border-radius: 50%;
  width: 30%;
}

/* flex-4 */


.flex-4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 110%;
  right: 8%;
}

.flex-4-img {
  width: 20%;
  border-radius: 50%;
}

.flex-font {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
}
.flex-4-h6 {
  font-weight: bold;
}

.flex-4-p {
  font-size: 12px;
  font-weight: 500;
  color: #5d5d5d;
}



@media only screen and (min-width: 1623px) {
 
  
  /* flex-2 */

.flex-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 87%;
  position: absolute;
  left: 38%;
}

.flex-2-img {
  border-radius: 50%;
  width: 30%;
}


/* flex-3 */


.flex-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 3%;
  position: absolute;
  right: 11%;
}

.flex-3-img {
  border-radius: 50%;
  width: 30%;
}

/* flex-4 */


.flex-4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 112%;
  right: 10%;
}

.flex-4-img {
  width: 20%;
  border-radius: 50%;
}

.flex-font {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
}
.flex-4-h6 {
  font-weight: bold;
}

.flex-4-p {
  font-size: 10px;
  font-weight: 500;
  color: #5d5d5d;
}

}

@media only screen and (max-width: 925px) {
 
  
.about-process-span {
  width: 50px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-process-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.2rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.about-process-content-1-h2 {
  font-size: 1.4rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.about-process-content-1-p{
  text-align: justify;
  width: 60%;
  color: #5d5d5d;
  font-size: .6rem;
  margin: 7px 0 10px 0px;
}

  /* flex-2 */

.flex-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 84%;
  position: absolute;
  left: 30%;
}

.flex-2-img {
  border-radius: 50%;
  width: 30%;
}
.flex-1-h6 {
  font-size: 13px;
  font-weight: bold;
}

.flex-1-p {
  font-size: 8px;
  font-weight: 500;
  color: #5d5d5d;
}


/* flex-3 */


.flex-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0%;
  position: absolute;
  right: 14%;
}

.flex-3-img {
  border-radius: 50%;
  width: 30%;
}

/* flex-4 */


.flex-4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 106%;
  right: 10%;
}

.flex-4-img {
  width: 20%;
  border-radius: 50%;
}

.flex-font {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
}
.flex-4-h6 {
  font-size: 13px;
  font-weight: bold;
}

.flex-4-p {
  font-size: 8px;
  font-weight: 500;
  color: #5d5d5d;
}

}



@media only screen and (max-width: 768px) {
 .about-process-bg-img {
  display: none
 }
.about-process-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.about-process-row-2{
  position: static;
  margin-top: 0px !important;
  margin-bottom: 0px !important;    
}

/* head */


.about-process-content-1 {
  position: static;
  margin: 20px 20px 0px 20px;
}

.about-process-span {
  width: 40px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-process-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.7rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.about-process-content-1-h2 {
  font-size: 1.9rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.about-process-content-1-p{
  text-align: left;
  width: 90%;
  color: #5d5d5d;
  font-size: .9rem;
  margin: 10px 0;
}


/* flex styles */

/* flex-1 */

.flex-1 {
  display: none;
}

.new-flex-1 {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  position: static;
}

.flex-1-img {
  width: 10%;
  border-radius: 50%;
  margin: 10px 0px;
}

.flex-1-h6 {
  font-size: 17px;
  font-weight: bold;
}

.flex-1-p {
  font-size: 13px;
  font-weight: 500;
  color: #5d5d5d;
}

/* flex-2 */

.flex-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: static;
}

.flex-2-img {
  border-radius: 50%;
  width: 10%;
  margin: 10px 0px;
}


/* flex-3 */


.flex-3 {
  display: none;
}

.new-flex-3 {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  position: static;
}

.flex-3-img {
  border-radius: 50%;
  width: 30%;
}
 

/* flex-4 */


.flex-4 {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  position: static;
}

.flex-4-img {
  width: 10%;
  border-radius: 50%;
}

.flex-font {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
}
.flex-4-h6 {
  font-size: 17px;
  font-weight: bold;
}

.flex-4-p {
  font-size: 13px;
  font-weight: 500;
  color: #5d5d5d;
}
}