.career-home-section {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.career-home-div {
  position: relative;
}

.career-home-content {
  position: absolute;
  top: 40%;
  /* text-align: center; */
}

.career-home-h4 {
  font-size: x-large;
  font-weight: 700;
}

.career-home-p {
  color: #666666;
  font-size: large;
  font-weight: 700;
  width: 40%
}

.career-home-button {
  margin-top: 40px;
  padding: 6px 20px !important;
  background-color: black !important;
  color: white !important;
  border-radius: 25px !important;
}

@media only screen and (max-width: 768px) {
  .career-home-section {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.career-home-row {
  display: flex;
  flex-direction: column-reverse;
}


.career-home-content {
  position: static;
  margin: 0px auto;
  text-align: center;
}

.career-home-h4 {
  font-size: x-large;
  font-weight: 700;
}

.career-home-p {
  color: #666666;
  font-size: large;
  font-weight: 700;
  width: 90%;
  margin: 0 auto;
}

.career-home-button {
  margin-top: 30px;
  margin-bottom: 40px;
  padding: 6px 20px !important;
  background-color: black !important;
  color: white !important;
  border-radius: 25px !important;
}

.career-home-lottie-div {
  margin-top: 40px;
}
}

@media only screen and (max-width: 576px) {
  .career-home-h4 {
  font-size: large;
  font-weight: 700;
}

.career-home-p {
  color: #666666;
  font-size: medium;
  font-weight: 700;
}

.career-home-button {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 6px 20px !important;
  background-color: black !important;
  color: white !important;
  border-radius: 25px !important;
  font-size: small !important;
}
}