.career-desc-line {
  width: 150px;
  height: 2px;
  background-color: #21e3ce;
  margin-top: 30px;
}

.career-desc-h2 {
  font-size: x-large;
  font-weight: 700;
  margin: 15px 0px;
}

/* card */

.career-desc-card-div {
  width: 100%;
  height: auto;
  border: solid 1px rgba(112, 112, 112, 0.5);
  background-color: #fff;
  padding: 20px 30px;
  margin: 10px 0px;
}

.career-disc-h3 {
  font-size: medium;
  font-weight: 700;
  margin-bottom: 13px;
}

.career-disc-p {
  font-size: x-small;
  font-weight: 600;
  width: 100%;
  margin-bottom: 3px;
}

.career-disc-h6 {
  font-size: x-small;
  color: rgba(0, 0, 0, 0.38);
  font-weight: 600;
  text-align: right;
  margin-bottom: 2px;
}

.career-disc-h6-2 {
  font-size: x-small;
  color: rgba(0, 0, 0, 0.38);
  font-weight: 600;
  margin-bottom: 2px;
  text-align: right;
}


.career-disc-btn {
  font-size: small;
  border: 1px solid black;
  color: black;
  font-weight: 500;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 4px;
  transition: 0.3s;
}


.career-disc-btn:hover {
  font-size: small;
  border: 1px solid black;
  background-color: black;
  color: white;
  font-weight: 500;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 4px;
  transition: 0.3s;
}


@media screen and (max-width: 992px) {
  .career-desc-card-div {
  width: 100%;
  height: auto;
  border: solid 1px rgba(112, 112, 112, 0.5);
  background-color: #fff;
  padding: 20px 30px;
  margin: 10px 0px;
}

}