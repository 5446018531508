.client-container {
  width: 100%;
  height: auto;
  margin: 0 !important;
  padding: 0 !important;
}

.row>* {
  padding: 0 !important;
}
.client-row-1 {
  margin: 0 !important;
  padding: 0 !important;
}
.client-content-1 {
  position: relative;
  height: min-content !important;
  min-height: 50%;

}
.client-img {
  width: 100%;
  z-index: 1;
  filter: brightness(0.4);
  position: relative;
}

.client-icon {
  position: absolute ;
  font-size: 3rem;
  z-index: 1;
  color:#fff;
  top: 42%;
  left: 53%;
  transform: translate(-50%, -50%);
 }


 .play-btn-wrap{
  position: absolute;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 1;
  }

.play-btn {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 55px; 
   width: 55px;
   background-color: #f55448;
   border-radius: 50%;
   color: #fff;
   font-size: 1.2rem;
   box-shadow: 0 0 20px 5px rgba(245, 84, 72, 0.5);  
   transition: 0.3s;
   position: relative;
   z-index: 2; 
}

.play-btn:hover{
  background: #f55448;
}

.play-btn-wrap:before{
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: #fff;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
  border-radius: 50%;
  opacity: 0.08;
  animation: scale 1.2s ease-out infinite 1s;
}


@keyframes scale{
  0%{
  transform: translate(-50%, -50%) scale(1);
  opacity: 0.15;
  }

  100%{
  transform: translate(-50%, -50%) scale(1.6);
  opacity: 0; 
  }
}


@media only screen and (max-width: 500px) {
  

  .client-icon {
  position: absolute ;
  font-size: 2rem;
  z-index: 1;
  color:#fff;
  top: 42%;
  left: 53%;
  transform: translate(-50%, -50%);
 }


.play-btn {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 40px; 
   width: 40px;
   background-color: #f55448;
   border-radius: 50%;
   color: #fff;
   font-size: 1rem;
   box-shadow: 0 0 20px 5px rgba(245, 84, 72, 0.5);  
   transition: 0.3s;
   position: relative;
   z-index: 2; 
}


.play-btn-wrap:before{
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #fff;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
  border-radius: 50%;
  opacity: 0.08;
  animation: scale 1.2s ease-out infinite 1s;
}

}


/* client logo part */
 

.client-row-2 {
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  background-color: #f8f9fa;
}

.client-content-2 {
  margin: 0 auto;
}
.client-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 30px 20px 30px 20px;
}


.client-logo-div {
  display: inline-block !important;
  border-radius: 0% !important;
  height: 100px !important;
  width: 100px !important;
  text-align:center !important;
  background-color: #f7f7f7 !important;
  border: 1px solid #f7f7f7 !important;
}

.client-logo {
  width: 100% !important;
  height : 100% !important;
  border-radius: 0% !important;
  object-fit: contain !important;
}

.client-logo-new {
  width: 80% !important;
  height : 80% !important;
  border-radius: 0% !important;
  object-fit: contain !important;
}




@media screen and (max-width: 992px) {
  .client-row-2 {
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  background-color: #f8f9fa;
}

.client-content-2 {
  margin: 0 auto;
}
.client-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 30px 30px 30px 30px;
}


.client-logo-div {
  display: inline-block !important;
  border-radius: 50% !important;
  height: 70px !important;
  width: 70px !important;
  text-align:center !important;
  background-color: #f7f7f7 !important;
  border: 1px solid #f7f7f7 !important;
}

.client-logo {
  width: 100% !important;
  height : 100% !important;
  border-radius: 50% !important;
  object-fit: contain !important;
}


}



@media screen and (max-width: 576px) {
  .client-row-2 {
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  background-color: #f8f9fa;
}

.client-content-2 {
  margin: 0 auto;
}
.client-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 10px 30px 10px 30px;
}


.client-logo-div {
  display: inline-block !important;
  border-radius: 50% !important;
  height: 50px !important;
  width: 50px !important;
  text-align:center !important;
  background-color: #f7f7f7 !important;
  border: 1px solid #f7f7f7 !important;
}

.client-logo {
  width: 100% !important;
  height : 100% !important;
  border-radius: 50% !important;
  object-fit: contain !important;
}


}

@media screen and (max-width: 443px) {
  .client-row-2 {
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  background-color: #f8f9fa;
}

.client-content-2 {
  margin: 0 auto;
}
.client-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 10px 30px 10px 30px;
}


.client-logo-div {
  display: inline-block !important;
  border-radius: 50% !important;
  height: 30px !important;
  width: 30px !important;
  text-align:center !important;
  background-color: #f7f7f7 !important;
  border: 1px solid #f7f7f7 !important;
}

.client-logo {
  width: 100% !important;
  height : 100% !important;
  border-radius: 50% !important;
  object-fit: contain !important;
}


}