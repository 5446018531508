.service-build-btn {
  border: 1px solid rgba(255, 108, 26, 0.7);
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  color: white;
  background-color:  #ff6c1a;
  padding: 8px 30px;
  border-radius: 6px;
  margin: 0px auto;
  transition: 0.3s;
}

.service-build-btn:hover {
 border: 1px solid #ff6c1a;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  color: white;
  padding: 8px 30px;
  border-radius: 6px;
  transition: 0.3s;
  box-shadow: 0 0 10px 1px gray;
  transition: 0.3s;
}