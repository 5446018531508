.contact-form-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}
.contact-form-head {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.contact-head-new {
  margin-top: 60px !important;
}

.contact-form-title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 2px;
}

.contact-line {
  width: 8%;
  height: 1px;
  background-color: #ff6c1a;
}

.contact-form-body-1 {
  background-color: rgba(102, 102, 102, 0.1);
  padding: 10px 15px;
  margin-top: 15px;
}

.contact-form-body-2 {
  background-color: rgba(102, 102, 102, 0.1);
  padding: 10px 15px;
  margin-top: 15px;
}

.contact-h6 {
  color: #666666;
  font-size: 12px;
}

.contact-h4 {
  color: black;
  font-size: 13px;
  font-weight: 600;
}

/* Form */

form { 
  margin-top: 15px;
}

.contact-input-div {
  margin-left: 50px;
}


.contact-input-div-1 {
  margin-left: 40px;
  margin-bottom: 5px;
}


.contact-input{
  border: 1px solid rgba(112, 112, 112, 0.28);
  border-radius: 5px;
  padding: 12px 5px 13px 15px;
  width: 100%;
  font-size: 13px;
  font-weight: 600;
}

.contact-input::placeholder{
  font-size: 12px;
  font-weight: 600;
  color: rgba(0, 0, 0) !important;
}

.form-select {
  border: 1px solid rgba(112, 112, 112, 0.28) !important;
  border-radius: 5px !important;
  padding: 12px 5px 15px 15px !important;
  width: 100% !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: rgba(0, 0, 0) !important;
}

.contact-input-div {
  display: flex;
  flex-direction: column;
}

.contact-input-div-1 {
  display: flex;
  flex-direction: column;
}

.contact-text-div {
  display: flex;
  flex-direction: column;
}


.error-text {
  font-size: 11px !important;
  font-weight: 500 !important;
} 

.textarea-input {
  margin-left: 50px;
  width: 96% !important;
  resize: none ;
}


.btn-info {
  outline: none !important;
  border: none !important;
  color: white !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  box-shadow: 0 15px 30px 0 rgba(20, 102, 204, 0.16)  !important;
  background-image: linear-gradient(to left, #f55047, #fc8459) !important;
  margin-bottom: 50px !important;
}



@media only screen and (max-width: 992px) {

  .container{
    max-width: 850px !important;
  }
  
.contact-input-div {
  margin-left: 40px;
}


.contact-input-div-1 {
  margin-left: 20px;
}


.textarea-input {
  margin-left: 40px;
  width: 100% !important;
}

.btn-info {
  outline: none !important;
  border: none !important;
  color: white !important;
  font-size: 9px !important;
  font-weight: 600 !important;
  box-shadow: 0 15px 30px 0 rgba(20, 102, 204, 0.16)  !important;
  background-image: linear-gradient(to left, #f55047, #fc8459) !important;
  margin-bottom: 50px !important;

}


}


@media only screen and (max-width: 768px) {

  .contact-form-container{
    max-width: 850px !important;
  }
  
.contact-input-div {
  margin-left: 40px;
}


.contact-input-div-1 {
  margin-left: 40px;
}


.textarea-input {
  margin-left: 40px;
  width: 72% !important;
}

.contact-input{
  border: 1px solid rgba(112, 112, 112, 0.28);
  border-radius: 5px;
  padding: 5px 5px 7px 15px;
  width: 100%;
}

.contact-input::placeholder{
  font-size: 12px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.38);
}

.form-select {
  border: 1px solid rgba(112, 112, 112, 0.28) !important;
  border-radius: 5px !important;
  padding: 8px 5px 10px 15px !important;
  width: 100% !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.38) !important;
  width: 100% !important;
}


.btn-info {
  outline: none !important;
  border: none !important;
  color: white !important;
  font-size: 9px !important;
  font-weight: 600 !important;
  box-shadow: 0 15px 30px 0 rgba(20, 102, 204, 0.16)  !important;
  background-image: linear-gradient(to left, #f55047, #fc8459) !important;
  margin-bottom: 50px !important;
}


}



@media only screen and (max-width: 576px) {

.contact-form-container{
    max-width: 850px !important;
  }
  
  .contact-form-head {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

  .contact-head-new {
    margin-top: 20px !important;
  }


.contact-input-div {
  margin-left: 10px;
}


.contact-input-div-1 {
  margin-left: 10px;
}


.textarea-input {
  margin-left: 10px;
  width: 90% !important;
}

.contact-input{
  border: 1px solid rgba(112, 112, 112, 0.28);
  border-radius: 5px;
  padding: 5px 5px 7px 15px;
  width: 90%;
}

.contact-input::placeholder{
  font-size: 12px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.38);
}

.form-select {
  border: 1px solid rgba(112, 112, 112, 0.28) !important;
  border-radius: 5px !important;
  padding: 8px 5px 10px 15px !important;
  width: 100% !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.38) !important;
  width: 90% !important;
}


.btn-info {
  outline: none !important;
  border: none !important;
  color: white !important;
  font-size: 9px !important;
  font-weight: 600 !important;
  box-shadow: 0 15px 30px 0 rgba(20, 102, 204, 0.16)  !important;
  background-image: linear-gradient(to left, #f55047, #fc8459) !important;
  margin-left:10px !important;
  margin-bottom: 50px !important;
}


}