.home-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  justify-content: space-between; 
  position: relative;
  z-index: 0;
}

/* video */

.back-video-div {
  width: 100%;
    margin-top: -5px;
}
.back-video {
  position: relative;
    width: 100%; 
    height: 100%;
    top: 0;
    left: 0;
    z-index:-1;
    filter: brightness(0.5);
}


.home-image {
  width: 100%;
  filter: brightness(.4);
}

 .home-content {
  width: 75%;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  position: absolute;
}

.home-content-p {
  color: white;
  text-align: center;
}

.home-content-title {
  color: white;
  font-size: 3.5rem;
  width: 95%;
  text-align: center;
  margin: 0 auto; 
  margin-bottom: 50px;
}

.home-content-title-orange {
  color: #ff6c1a;
}

.home-content-btn {
  width: 12%;
  border: 1px solid white;
  background: transparent;
  color: #fff;
  padding: 0.6rem .8rem;
  font-size: .7rem;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 7px;
  margin: 0 auto; 
}

.home-content-btn:hover {
  color: #fff;
  background-color: #ff6c1a;
  transition: all 0.5s;
  border: 1px solid #ff6c1a
} 



 
@media only screen and (max-width: 979px) {
  
 .home-content {
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
}

.home-content-title {
  color: white;
  font-size: 3rem;
  width: 85%;
  text-align: center;
  margin: 0 auto; 
  margin-bottom: 50px;
}

}


@media only screen and (max-width: 784px) {
  
 .home-content {
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
}

.home-content-title {
  color: white;
  font-size: 2.2rem;
  width: 85%;
  text-align: center;
  margin: 0 auto; 
  margin-bottom: 50px;
}

.home-content-btn {
  width: 18%;
  border: 1px solid white;
  background: transparent;
  color: #fff;
  padding: 0.6rem .8rem;
  font-size: .6rem;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 10px;
  margin: 0 auto; 
}
  

.home-content-p {
  color: white;
  text-align: center;
  font-size: 12px;
}
}



@media only screen and (max-width: 600px) {
  
 .home-content {
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
  margin-top: 20px;
}

.home-content-title {
  color: white;
  font-size: 1.8rem;
  width: 90%;
  text-align: center;
  margin: 0 auto; 
  margin-bottom: 20px;
}

.home-content-btn {
  width: 24%;
  border: 1px solid white;
  background: transparent;
  color: #fff;
  padding: 0.6rem .8rem;
  font-size: .6rem;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 10px;
  margin: 0 auto; 
}
  

.home-content-p {
  color: white;
  text-align: center;
  font-size: 12px;
}
}

@media only screen and (max-width: 450px) {
  
 .home-content {
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
  margin-top: 30px;
}

.home-content-title {
  color: white;
  font-size: 1.3rem;
  width: 90%;
  text-align: center;
  margin: 0 auto; 
  margin-bottom: 10px;
}

.home-content-btn {
  width: 20%;
  border: 1px solid white;
  background: transparent;
  color: #fff;
  padding: 0.5rem .6rem;
  font-size: .4rem;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 10px;
  margin: 0 auto; 
}
  

.home-content-p {
  color: white;
  text-align: center;
  font-size: 8px;
}
}