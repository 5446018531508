
/* section 1 */
.career-fun-1 {
  margin: 50px auto 0px auto;
}

.career-fun-span {
  width: 50px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.career-fun-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 3.5rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.career-fun-1-h2 {
  font-size: 2.3rem;  
  font-weight: bold;
  margin-top: -1.6rem;
}


/* gallery */


.career-fun-img-div {
  width: auto;
  height: auto;
  max-width: 100%;
  min-height: auto;
  margin: 4px 2px;
}
.career-fun-img-div-1 {
  width: auto;
  height: auto;
  max-width: 100%;
  min-height: auto;
  margin: 4px 2px;
}

.career-fun-img {
  width: 100%;
  height: auto;
}


.career-fun-img-1 {
  width: 100%;
  height: 100%;
}


@media only screen and (max-width: 576px) {
  
/* section 1 */
.career-fun-1 {
  margin: 50px auto 0px auto;
}

.career-fun-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.career-fun-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.5rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.career-fun-1-h2 {
  font-size: 1.8rem;  
  font-weight: bold;
  margin-top: -1.6rem;
}


/* gallery */


.career-fun-img-div {
  width: auto;
  height: auto;
  max-width: 400px;
  min-height: auto;
  margin: 4px 2px;
}
.career-fun-img-div-1 {
  width: auto;
  height: auto;
  max-width: 400px;
  min-height: auto;
  margin: 4px 2px;
}

.career-fun-img {
  width: 100%;
  height: auto;
}


.career-fun-img-1 {
  width: 100%;
  height: 100%;
}

}