.portfolio-container {
  width: 100%;
  height:auto;
  background-color: white;
  margin: 40px auto;
}

/* section 1 */
.portfolio-content-1 {
  margin-top: 50px;
}

.portfolio-span {
  width: 50px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.portfolio-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 3.5rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.portfolio-content-1-h2 {
  font-size: 2rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.portfolio-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 20px 0;
  font-size: .9rem;;
}

/* section-2-div */
.portfolio-content-div-2 {
  margin-top: 140px !important;
}
.portfolio-content-div-p {
  font-size: 12px;
  font-weight: bold;
  color:#ff6c1a;
  margin-left: 110px;
  cursor: pointer;
}

.portfolio-content-div-p a {
  color: black;
  font-size: 13px;
  margin-left: 10px;
}

.portfolio-content-div-p a:hover {
  color: black;
}

/* section 2 */

.portfolio-content-2-image-wrap{
  column-width: 300px;
  column-gap: 5px;
}

.portfolio-2-img{
  width: 100%;
  position:  absolute;
  height: 100%;
  width: initial;
  transition: 0.5s;
  max-width: 100%;
}

.portfolio-2-img-div{
  margin-top: 5px;
  overflow: hidden;
  height: 300px;
  Width: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
}

.portfolio-2-img-div .portfolio-content-2-h1 {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  color: white;
  background-color: rgba(10, 72, 51, 0.55);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
}


.portfolio-2-img-div:hover .portfolio-content-2-h1{
  opacity: 1;
}



@media only screen and (min-width: 1400px) {
/* section 2 */

.portfolio-content-2-image-wrap{
  column-width: 400px;
  column-gap: 5px;
}
}

@media only screen and (max-width: 998px) {
  /* section 1 */
.portfolio-content-1 {
  margin-top: 40px;
}

.portfolio-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.portfolio-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 3rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.portfolio-content-1-h2 {
  font-size: 1.6rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.portfolio-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 20px 0;
  font-size: .7rem;;
}


/* section-2-div */
.portfolio-content-div-2 {
  margin-top: 100px !important;
}
.portfolio-content-div-p {
  font-size: 11px;
  font-weight: bold;
  color:#ff6c1a;
  margin-left: 30px;
}

.portfolio-content-div-p a {
  color: black;
  font-size: 11px;
  margin-left: 6px;
}
}

@media only screen and (max-width: 767px) {
  /* section 1 */
.portfolio-content-1 {
  margin-top: 40px;
}

.portfolio-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.portfolio-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.7rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.portfolio-content-1-h2 {
  font-size: 1.4rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.portfolio-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 10px 0 20px 0;
  font-size: .6rem;;
}


/* section-2-div */
.portfolio-content-div-2 {
  margin-top: 100px !important;
}
.portfolio-content-div-p {
  font-size: 11px;
  font-weight: bold;
  color:#ff6c1a;
}

.portfolio-content-div-p a {
  color: black;
  font-size: 11px;
  margin-left: 6px;
}

/* section 2 */

.portfolio-content-2-image-wrap{
  column-width: 200px;
  column-gap: 5px;
  /* margin: 0 auto; */
}

.portfolio-2-img{
  width: 100%;
  position: absolute;
  height: 100%;
  width: initial;
  transition: 0.5s;
}

.portfolio-2-img-div{
  margin-top: 5px;
  overflow: hidden;
  height: 300px;
  Width: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
}

.portfolio-2-img-div .portfolio-content-2-h1 {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  color: white;
  background-color: rgba(10, 72, 51, 0.55);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
}


.portfolio-2-img-div:hover .portfolio-content-2-h1{
  opacity: 1;
}

}

@media only screen and (max-width: 576px) {
  /* section 1 */
.portfolio-content-1 {
  margin-top: 20px;
  margin-left: 20px;

}

.portfolio-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.portfolio-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.3rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.portfolio-content-1-h2 {
  font-size: 1.1rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.portfolio-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 10px 0 10px 0;
  font-size: .5rem;;
}


/* section-2-div */
.portfolio-content-div-2 {
  margin-top: 60px !important;
}
.portfolio-content-div-p {
  font-size: 8px;
  font-weight: bold;
  color:#ff6c1a;
}

.portfolio-content-div-p a {
  color: black;
  font-size: 9px;
}
/* section 2 */

.portfolio-content-2-image-wrap{
  column-width: 300px;
  column-gap: 5px;
  margin: 0 60px; 
}

.portfolio-2-img{
  width: 100%;
  position: absolute;
  height: 100%;
  width: initial;
  transition: 0.5s;
  border-radius: 10px;
  transform-origin: center;

}

.portfolio-2-img-div{
  margin-top: 5px;
  overflow: hidden;
  height: 300px;
  Width: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  transform-origin: center;
}

.portfolio-2-img-div .portfolio-content-2-h1 {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  color: white;
  background-color: rgba(10, 72, 51, 0.55);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
}


.portfolio-2-img-div:hover .portfolio-content-2-h1{
  opacity: 1;
}

}


@media only screen and (max-width: 340px) {
  /* section 1 */
.portfolio-content-1 {
  margin-top: 20px;
  margin-left: 20px;

}

.portfolio-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.portfolio-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.3rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.portfolio-content-1-h2 {
  font-size: 1.1rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.portfolio-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 10px 0 10px 0;
  font-size: .5rem;;
}


/* section-2-div */
.portfolio-content-div-2 {
  margin-top: 60px !important;
}
.portfolio-content-div-p {
  font-size: 7px;
  font-weight: bold;
  color:#ff6c1a;
}

.portfolio-content-div-p a {
  color: black;
  font-size: 8px;
}
/* section 2 */

.portfolio-content-2-image-wrap{
  column-width: 300px;
  column-gap: 5px;
  margin: 0 60px; 
}

.portfolio-2-img{
  width: 100%;
  position: absolute;
  height: 100%;
  width: initial;
  transition: 0.5s;
  border-radius: 10px;
  transform-origin: center;

}

.portfolio-2-img-div{
  margin-top: 5px;
  overflow: hidden;
  height: 300px;
  Width: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  transform-origin: center;
}

.portfolio-2-img-div .portfolio-content-2-h1 {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  color: white;
  background-color: rgba(10, 72, 51, 0.55);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
}


.portfolio-2-img-div:hover .portfolio-content-2-h1{
  opacity: 1;
}

}