.about-client-container {
  width: 100%;
  height: auto;
  margin: 0 !important;
  padding: 0 !important;
}

.row>* {
  padding: 0 !important;
}
.about-client-row-1 {
  margin: 0 !important;
  padding: 0 !important;
}
.about-client-content-1 {
  position: relative;
  height: min-content !important;
  min-height: 50%;

}
.about-client-img {
  width: 100%;
  z-index: 1;
  filter: brightness(0.4);
  position: relative;
}

.about-client-icon {
  position: absolute ;
  font-size: 3rem;
  z-index: 1;
  color:#fff;
  top: 42%;
  left: 53%;
  transform: translate(-50%, -50%);
 }


 .about-play-btn-wrap{
  position: absolute;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 1;
  }

.about-play-btn {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 55px; 
   width: 55px;
   background-color: #f55448;
   border-radius: 50%;
   color: #fff;
   font-size: 1.2rem;
   box-shadow: 0 0 20px 5px rgba(245, 84, 72, 0.5);  
   transition: 0.3s;
   position: relative;
   z-index: 2; 
}

.about-play-btn:hover{
  background: #f55448;
}

.about-play-btn-wrap:before{
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: #fff;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
  border-radius: 50%;
  opacity: 0.08;
  animation: scale 1.2s ease-out infinite 1s;
}


@keyframes scale{
  0%{
  transform: translate(-50%, -50%) scale(1);
  opacity: 0.15;
  }

  100%{
  transform: translate(-50%, -50%) scale(1.6);
  opacity: 0; 
  }
}


@media only screen and (max-width: 500px) {
  

  .about-client-icon {
  position: absolute ;
  font-size: 2rem;
  z-index: 1;
  color:#fff;
  top: 42%;
  left: 53%;
  transform: translate(-50%, -50%);
 }


.about-play-btn {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 40px; 
   width: 40px;
   background-color: #f55448;
   border-radius: 50%;
   color: #fff;
   font-size: 1rem;
   box-shadow: 0 0 20px 5px rgba(245, 84, 72, 0.5);  
   transition: 0.3s;
   position: relative;
   z-index: 2; 
}


.about-play-btn-wrap:before{
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #fff;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
  border-radius: 50%;
  opacity: 0.08;
  animation: scale 1.2s ease-out infinite 1s;
}

}


/* client logo part */
 

.about-client-row-2 {
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  background-color: #f8f9fa;
}

.about-client-content-2 {
  margin: 0 auto;
}
.about-client-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 60px 20px 20px 20px;
}


.about-client-logo-div {
  display: flex;
  justify-content: center;
}

.about-client-logo {
  width:45% !important;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  background: #fff;
  padding: 18px 9px;
  
}

.about-client-logo-1 {
  width:67% !important;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  background: #fff;
  padding: 19px 8px;
}

.swiper-button-prev:after{
  color: black;
  font-size: 32px !important;
  margin-right: 30px !important;
  margin-top: 30px !important;
}

.swiper-button-next:after{
  color: black;
  font-size: 32px !important;
  margin-top: 30px !important;
}



@media only screen and (max-width: 1148px) {
  
.about-client-row-2 {
  margin: 0 !important;
  padding: 0 !important;
  background-color: #f8f9fa;
}

.about-client-content-2 {
  margin: 0 auto;
}
.about-client-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin:40px 5px 10px 5px;
}


.about-client-logo-div {
  display: flex;
  justify-content: center;
}

.about-client-logo {
  width:40% !important;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  padding: 16px 11px;
  
}

.about-client-logo-1 {
  width:60% !important;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  padding: 17px 10px;
}

}


@media only screen and (max-width: 950px) {
  
.about-client-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin:40px 30px 10px 30px;
}


.about-client-logo-div {
  display: flex;
  justify-content: center;
}

.about-client-logo {
  width:35% !important;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  padding: 12px 11px;
  
}

.about-client-logo-1 {
  width:50% !important;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  padding: 14px 10px;
}

.swiper-button-prev:after{
  font-size: 22px !important;
  margin-right: 27px !important;
  margin-top: 30px !important;
}

.swiper-button-next:after{
  font-size: 22px !important;
  margin-top: 30px !important;
}

}

@media only screen and (max-width: 700px) {
  
.about-client-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin:40px 30px 10px 30px;
}


.about-client-logo-div {
  display: flex;
  justify-content: center;
}

.about-client-logo {
  width:50% !important;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  padding: 10px 7px;
  
}

.about-client-logo-1 {
  width:72% !important;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  padding: 13px 9px;
}

}

@media only screen and (max-width: 500px) {
  
.about-client-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin:25px 20px 7px 20px;
}


.about-client-logo-div {
  display: flex;
  justify-content: center;
}

.about-client-logo {
  width:55% !important;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  padding: 10px 7px;
  
}

.about-client-logo-1 {
  width:81% !important;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  padding: 12px 8px;
}

.swiper-button-prev:after{
  font-size: 15px !important;
  margin-top: 20px !important;
}

.swiper-button-next:after{
  font-size: 15px !important;
  margin-top: 20px !important;
}


}

@media only screen and (max-width: 400px) {
  
.about-client-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin:25px 25px 7px 25px;
}


.about-client-logo-div {
  display: flex;
  justify-content: center;
}

.about-client-logo {
  width:60% !important;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  padding: 9px 7px;
}

.about-client-logo-1 {
  width:85% !important;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  padding: 10px 8px;
}

.swiper-button-prev:after{
  font-size: 15px !important;
  margin-top: 20px !important;
}

.swiper-button-next:after{
  font-size: 15px !important;
  margin-top: 20px !important;
}


}