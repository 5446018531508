.web-tech-p {
  font-size: 14px;
  width: 90%;
}

.web-tech-card {
  border-radius: 0px !important;
  border: 1px solid black !important;
  padding: 15px 0px !important;
  column-gap: 1px !important;
  margin: 1px 1px;
  width: 99%;
  height: 170px !important;
  position: relative;
}

.web-tech-img-div {
  width: auto;
  height: auto;
  max-width: 70px;
  max-height: 70px;
  margin: 5px auto;
   position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
}

.web-tech-img {
  width: 100%;
  height: 100%;
}

.web-tech-h6 {
  text-align: center;
  margin: 10px auto;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
}

@media screen and (max-width: 576px) {
  .web-tech-p {
  font-size: 12px;
  width: 90%;
}

}