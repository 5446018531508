.tc-section {
  background-color: #fbfbfb;
}

.tc-line {
  width: 10%;
  height: 4px;
   box-shadow: 0 15px 30px 0 rgba(20, 102, 204, 0.16);
  background-color: #ff6c1a;
}

.tc-p {
  font-size: 12px !important;
}
