.herosection{
    background-color: #e3c5cfe6;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
}
.herotext{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    line-height: 1.5;
    padding: 1rem 0;
}
.herohead{
    margin-top: .5rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    line-height: 1.5;
}
.herologo {
    width: 200px;
    height: 100px;
}
.heroimg{
    width: 80%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1.5rem;
    margin: 0 auto;
    align-self: center;
}
.herourl{
    display: flex;
    justify-content: center;
    align-items: center; 
}
.herourl a {
    color: black;
    margin-left: 0.5rem;
    margin-top: 1.5rem;
}
.herourl img {
    margin-top: 1.5rem;
    height: 30px;
}

.blockChainLearn_imgLeft{
    
    margin-left: 20%;
    margin-top: 10%;
}

.blockChainLearn_imgRight{
    width: 80%;
    margin-top: 10%;
}

@media screen and (max-width: 1150px) and (min-width: 990px) {
    
    .blockChainLearn_imgLeft{
     
        margin-left: 20%;
        margin-top: 25%;
    }
    
    .blockChainLearn_imgRight{
     
        margin-top: 25%;
    }
    
  }

  @media screen and (max-width:990px) {  

    .blockChainLearn_imgLeft{
      display: none;
    }

    .blockChainLearn_imgRight{
       width: 50%;
       margin-left: 25%;
      margin-top: 0%;
      padding-bottom: 5%;

    }
  }
/* @media (min-width:320px) and (max-width:780px) {
    .herosection{
        display: block;
        font-size: 0.8rem;
    }
    .herologo {
        width: 15vh;
        height: 100px;
        display: block;
        margin: 0 auto;
    }
    .herohead{
        margin-top: .2rem;
        margin-bottom: 0;
        font-size: 0.8rem;
        line-height: 1.5;
    }
    .heroimg{
        width: 80%;
        padding: 0.5rem 1rem;
        margin: 0 auto;
    }
    .herourl{
        font-size: 0.6rem;
    }
    .herourl img {
        margin-top: 1.5rem;
        height: 15px;
    }
} */