.contact-delivery-container{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.contact-delivery-ellipse{
  width: 70px;
  height: 70px;
  border-radius: 50%;
    /* background-image: linear-gradient(to left, #f55047, #fc8459); */
  background-color: #21e3ce;
  margin-top: 50px;
  opacity: 0.5;
}

.contact-delivery-h1 {
  font-size: 2.5rem;
  color: black;
  padding-top: 10px;
  font-weight: 600;
}

.contact-delivery-h3 {
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
}

.contact-delivery-p {
  font-size:10px;
  color: #5d5d5d;
  width: 70%;
  margin-bottom: 90px;
}

@media only screen and (max-width: 1000px) {
  .contact-delivery-ellipse{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #21e3ce;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  margin-top: 50px;
  margin-left : 30px;
}

.contact-delivery-h1 {
  font-size: 2.5rem;
  color: #8c8f8f;
  padding-top: 10px;
  font-weight: 600;
}

.contact-delivery-h3 {
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  margin-left : 30px;
}

.contact-delivery-p {
  font-size:10px;
  color: #5d5d5d;
  width: 70%;
  margin-bottom: 40px;
  margin-left : 30px;
}

} 

@media only screen and (max-width: 768px) {
  .contact-delivery-ellipse{
  width: 70px;
  height: 70px;
  border-radius: 50%;
    background-image: linear-gradient(to left, #f55047, #fc8459);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  margin-top: 50px;
  margin-left : 10px;
}

.contact-delivery-h1 {
  font-size: 2.5rem;
  color: #fff;
  padding-top: 10px;
  font-weight: 600;
}

.contact-delivery-h3 {
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  margin-left : 10px;
}

.contact-delivery-p {
  font-size:10px;
  color: #5d5d5d;
  width: 70%;
  margin-bottom: 40px;
  margin-left : 10px;
}

} 


@media only screen and (max-width: 576px) {
  .contact-delivery-ellipse{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-image: linear-gradient(to left, #f55047, #fc8459);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  margin-top: 10px;
  margin-left : 20px;
}

.contact-delivery-h1 {
  font-size: 1.9rem;
  color: #fff;
  padding-top: 15px;
  font-weight: 600;
}

.contact-delivery-h3 {
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  margin-left : 20px;
}

.contact-delivery-p {
  font-size:10px;
  color: #5d5d5d;
  width: 70%;
  margin-bottom: 40px;
  margin-left : 20px;
}

} 