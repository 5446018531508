.about-container {
  width: 100%;
  height:auto;
  background-color: white;
  margin: 90px auto;
  margin-top: 0px;
}

/* section 1 */
.about-content-1 {
  margin-top: 90px;
}

.about-span {
  width: 50px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 3rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.about-content-1-h2 {
  font-size: 2rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.about-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 20px 0;
  font-size: .9rem;;
}

.about-content-1-p{
  text-align: justify;
  width: 80%;
  color: #5d5d5d;
  font-size: .8rem;
  margin: 40px 0;
}

/* content-2 */

.about-content-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  margin-bottom: 20px;
}

.about-content-2-btn {
  width: 16%;
  padding: 0.3rem .7rem;
  background-image: linear-gradient(to left, #f55047, #fc8459);
  padding: 0.6rem .5rem;
  border-radius: 6px;
  border:none;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  color: #fff;
  margin: 0px 0 20px 0;
}

.about-content-2-h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10 px;
}

.about-content-2-p {
  font-size: 15px;
  font-weight: 600;
}

/* section 2 */

.about-section-2 {
  display: flex;
  justify-content: flex-start;
}


.about-section-content {
  width: 100%;
  height: 100%;
}

/* .about-img-1 {
  width: 200px;
  border-radius: 10px;
  margin-bottom: 90px;
}

.about-img-2 {
  width: 250px;
  border-radius: 10px;
  margin-bottom: 90px;
}

.about-position-img-1 {
  position: absolute;
  top: 250px;
  right : 30px;
  z-index: 1;
}

.about-position-img-2 {
  position: absolute;
  top : 80px;
  right : -110px;
  z-index: 0;
} */


@media only screen and (max-width: 1000px) {
 
  .about-container {
  width: 100%;
  height: auto;
  background-color: white;
  /* margin: 0 !important; */
}

/* section 1 */
.about-content-1 {
  margin-top: 40px;
}

.about-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.5rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.about-content-1-h2 {
  font-size: 1.6rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.about-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 20px 0;
  font-size: .8rem;;
}

.about-content-1-p{
  text-align: justify;
  width: 90%;
  color: #5d5d5d;
  font-size: .8rem;
  margin: 20px 0;
}

/* content-2 */

.about-content-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 76%;
}

.about-content-2-btn {
  width: 30%;
  padding: 0.3rem .7rem;
  background-image: linear-gradient(to left, #f55047, #fc8459);
  padding: 0.5rem .6rem;
  border-radius: 6px;
  border:none;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: #fff;
  margin: 20px 0 20px 0;
}

.about-content-2-h1 {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 10 px;
}

.about-content-2-p {
  font-size: 12px;
  font-weight: 600;
}

/* section 2 */

.about-section-2 {
  display: flex;
  justify-content: center;
}


.about-section-content {
  position: relative;
}

.about-img-1 {
  width: 150px;
  /* height: 350px; */
  border-radius: 10px;
}

.about-img-2 {
  width: 180px;
  /* height: 400px;  */
  border-radius: 10px;
}

.about-position-img-1 {
  position: absolute;
  top: 250px;
  right : 10px;
  z-index: 1;
}

.about-position-img-2 {
  position: absolute;
  top : 100px;
  right : -100px;
}
}


@media only screen and (max-width: 768px) {
 
  .about-container {
  width: 100%;
  height: auto;
  margin: 30px auto;
  background-color: white;
}

/* section 1 */
.about-content-1 {
  margin-top: 10px;
}

.about-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 3rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.about-content-1-h2 {
  font-size: 2rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.about-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 10px 0;
  font-size: .9rem;;
}

.about-content-1-p{
  text-align: justify;
  width: 100%;
  color: #5d5d5d;
  font-size: .8rem;
  margin: 30px 0;
}

/* content-2 */

.about-content-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.about-content-2-btn {
  width: 20%;
  background-image: linear-gradient(to left, #f55047, #fc8459);
  padding: 0.6rem .8rem;
  border-radius: 6px;
  border:none;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  color: #fff;
  margin: 0px 0 20px 0;
}

.about-content-2-h1 {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.about-content-2-p {
  font-size: 12px;
  font-weight: 600;
}

.about-section-content{
  display: none;
}

}


@media only screen and (max-width: 600px) {
 
  .about-container {
  width: 100%;
  height: auto;
  background-color: white;
  margin: 20px auto;
}


/* section 1 */
.about-content-1 {
  margin-top: 10px;
}

.about-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 3rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
  margin: 0 10px;
}

.about-content-1-h2 {
  font-size: 2rem;  
  font-weight: bold;
  margin-top: -1rem;
  margin: 0 10px;
}

.about-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 10px 10px;
  font-size: .9rem;

}

.about-content-1-p{
  text-align: justify;
  width: 90%;
  color: #5d5d5d;
  font-size: .8rem;
  margin: 30px 10px;
}

/* content-2 */

.about-content-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 0 10px;
}

.about-content-2-btn {
  width: 20%;
  background-image: linear-gradient(to left, #f55047, #fc8459);
  padding: 0.6rem .8rem;
  border-radius: 6px;
  border:none;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  color: #fff;
  margin: 20px 0 0px 10px;
}

.about-content-2-h1 {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 10 px;
}

.about-content-2-p {
  font-size: 12px;
  font-weight: 600;
}

.about-img-1 {
  display: none;
}

.about-img-2 {
  display: none;
}

}

@media only screen and (max-width: 450px) {
 
  .about-container {
  width: 100%;
  height: auto;
  background-color: white;
  margin: 20px auto;
}


/* section 1 */
.about-content-1 {
  margin-top: 10px;
}

.about-span {
  width: 20px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.5rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
  margin: 0 10px;
}

.about-content-1-h2 {
  font-size: 1.7rem;  
  font-weight: bold;
  margin-top: -1rem;
  margin: 0 10px;
}

.about-content-1-h6{
  color: black;
  letter-spacing: .2px;
  margin: 10px 10px;
  font-size: .7rem;

}

.about-content-1-p{
  text-align: justify;
  width: 90%;
  color: #5d5d5d;
  font-size: .7rem;
  margin: 30px 10px;
}

/* content-2 */

.about-content-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 0 10px;
}

.about-content-2-btn {
  width: 28%;
  background-image: linear-gradient(to left, #f55047, #fc8459);
  padding: 0.6rem .9rem;
  border-radius: 6px;
  border:none;
  font-size: 9px;
  font-weight: 600;
  letter-spacing: 0;
  color: #fff;
  margin: 10px 0 10px 10px;
}

.about-content-2-h1 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10 px;
}

.about-content-2-p {
  font-size: 10px;
  font-weight: 600;
}

.about-img-1 {
  display: none;
}

.about-img-2 {
  display: none;
}

}