@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,500&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
