.service-container {
  width: 100%;
  height:auto;
  background-color: white;
  margin: 40px auto;
}
/* .menu-icon-bg{
background-color: #000 !important;
color: #000 !important;
} */

/* section 1 */
.service-content-1 {
  margin-top: 60px;
}

.service-span {
  width: 50px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.service-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 3.5rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.service-content-1-h2 {
  font-size: 2rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.service-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 20px 0;
  font-size: .9rem;;
}



/* section-2-div */
.service-content-div-2 {
  margin-top: 140px !important;
}
.service-content-div-p {
  font-size: 12px;
  font-weight: bold;
  color:#ff6c1a;
  margin-left: 110px;
  cursor: pointer;
}

.service-content-div-p a {
  color: black;
  font-size: 13px;
  margin-left: 10px;
}


.service-content-div-p a:hover {
  color: black;
}

/* section-2 */

.service-section-2{
  margin-left: 60px !important;
  margin-top: 30px !important;
}

.service-content-2-h1 {
  color:#8c8f8f;
  font-size: 30px;
  letter-spacing: normal;
  text-align: left;
  font-weight: bold
}

.service-content-2-h5 {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.service-content-new-2 {
  width: 30% !important;
}
.service-content-2-p{
  text-align: left;
  width: 80% !important;
  color: #5d5d5d;
  font-size: .8rem;  
}



@media only screen and (max-width: 1200px) {
  
/* section-2 */

.service-section-2{
  margin-left: 70px !important;
  margin-top: 30px !important;
}

.service-content-2-h1 {
  color:#8c8f8f;
  font-size: 27px;
  letter-spacing: normal;
  text-align: left;
  font-weight: bold
}

.service-content-2-h5 {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.service-content-2-p{
  text-align: left;
  width: 60% !important;
  color: #5d5d5d;
  width: 70% !important;
  font-size: .8rem;  
}

}


@media only screen and (max-width: 998px) {
  
/* section-2 */

.service-section-2{
  margin-left: 40px !important;
  margin-top: 30px !important;
}

.service-content-2-h1 {
  color:#8c8f8f;
  font-size: 27px;
  letter-spacing: normal;
  text-align: left;
  font-weight: bold
}

.service-content-2-h5 {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.service-content-2-p{
  text-align: left;
  width: 60% !important;
  color: #5d5d5d;
  width: 80% !important;
  font-size: .8rem;  
}


/* section-2-div */
.service-content-div-2 {
  margin-top: 140px !important;
}
.service-content-div-p {
  font-size: 12px;
  font-weight: bold;
  color:#ff6c1a;
  margin-left: 0px;
}

.service-content-div-p a {
  color: black;
  font-size: 13px;
  margin-left: 10px;
}

}


@media only screen and (max-width: 750px) {
  
/* section 1 */
.service-content-1 {
  margin-top: 50px;
}

.service-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.service-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.8rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.service-content-1-h2 {
  font-size: 1.5rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.service-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 20px 0;
  font-size: .7rem;;
}

.service-content-1-p{
  text-align: left;
  width: 60%;
  color: #5d5d5d;
  font-size: .8rem;
  margin: 40px 0;
}
  
/* section-2 */

.service-section-2{
  margin-left: 0px !important;
  margin-top: 30px !important;
}



.service-content-2-h1 {
  color:#8c8f8f;
  font-size: 24px;
  letter-spacing: normal;
  text-align: left;
  font-weight: bold
}

.service-content-2-h5 {
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.service-content-2-p{
  text-align: left;
  width: 60% !important;
  color: #5d5d5d;
  width: 80% !important;
  font-size: .6rem;  
}


/* section-2-div */
.service-content-div-2 {
  margin-top: 140px !important;
}
.service-content-div-p {
  font-size: 12px;
  font-weight: bold;
  color:#ff6c1a;
  margin-left: 0px;
}

.service-content-div-p a {
  color: black;
  font-size: 13px;
  margin-left: 10px;
}

}


@media only screen and (max-width: 576px) {
  .service-container {
  width: 100%;
  height:auto;
  background-color: white;
  margin: 40px auto;
}
  
/* section 1 */
.service-content-1 {
  margin: 30px 0px 10px 10px;
}

.service-span {
  width: 20px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.service-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.2rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.service-content-1-h2 {
  font-size: 1.1rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.service-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 10px 0;
  font-size: .6rem;;
}

  
/* section-2 */

.service-section-2{
  margin: 20px 40px 10px 40px !important;
}

.service-content-2 {
  margin-left: 30px;
}

.service-content-new-2 {
  margin-left: 30px;
}

.service-content-2-h1 {
  color:#8c8f8f;
  font-size: 27px;
  letter-spacing: normal;
  text-align: left;
  font-weight: bold
}

.service-content-2-h5 {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.service-content-2-p{
  text-align: left;
  width: 80% !important;
  color: #5d5d5d;
  font-size: .6rem;  
}

.service-content-new-2-p {
  text-align: left;
  width: 80% !important;
  color: #5d5d5d;
  font-size: .6rem; 
}

.service-content-new-2 {
  
  width: 90% !important;
}


/* section-2-div */
.service-content-div-2 {
  margin-top: 60px !important;
}
.service-content-div-p {
  font-size: 10px;
  font-weight: bold;
  color:#ff6c1a;
}

.service-content-div-p a {
  color: black;
  font-size: 11px;
  margin-left: 6px;
}

}


@media only screen and (max-width: 440px) {
  .service-container {
  width: 100%;
  height:auto;
  background-color: white;
  margin: 20px auto;
}
  
/* section 1 */
.service-content-1 {
  margin: 30px 0px 10px 10px;
}

.service-span {
  width: 20px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.service-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 1.8rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.service-content-1-h2 {
  font-size: .9rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.service-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 10px 0;
  font-size: .4rem;;
}

  
/* section-2 */

.service-section-2{
  margin: 20px 40px 0px 40px !important;
}

.service-content-2{
  margin-left: 30px;
}

.service-content-new-2{
  margin-left: 30px;
}

.service-content-2-h1 {
  color:#8c8f8f;
  font-size: 27px;
  letter-spacing: normal;
  text-align: left;
  font-weight: bold
}

.service-content-2-h5 {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.service-content-2-p{
  text-align: left;
  width: 90% !important;
  color: #5d5d5d;
  font-size: .6rem;  
}

.service-content-new-2-p {
  text-align: left;
  width: 90% !important;
  color: #5d5d5d;
  font-size: .6rem; 
}

.service-content-new-2 {
  
  width: 80% !important;
}


/* section-2-div */
.service-content-div-2 {
  margin-top: 60px !important;
}
.service-content-div-p {
  font-size: 8px;
  font-weight: bold;
  color:#ff6c1a;
  cursor: pointer;
}

.service-content-div-p a {
  color: black;
  font-size: 9px;
  margin-left: 6px;
}

}
