.Blockchainpage {
  background-color: rgb(237, 234, 234);
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.App-header {
  margin-left: 5rem;
  margin-right: 5rem;
  padding: 3.5rem;
  background-color: white;
}
.content {
    line-height: 1.5;
}
.chead{
    margin-top: 1rem;
    /* font-size: 34px; */
}
.ctex{
    margin-top: 1rem;
    text-align: justify;
    line-height: 2;
    text-decoration:none;
}
.cbutton {
  content: " ";
  height: auto;
  margin-top: .5rem;
  padding: .8rem;
  display: block;
  border: #e398b1e6 solid 1px;
  background-color: #e3c5cfe6;
}
.cbutton ::after{
    padding: 0.5rem;
    margin-top: 1rem;
    height: auto;
}
.cbutton ol li a {
  color: black;
} 
.cbutton ol li a:hover {
  transition: 2s;
} 

.btntxt {
  border: none;
  background: none;
}

  li {
    display: block;
    margin-top: .5rem;
    /* width: 90vw; */
  }
  
  .linktext, .linktext1, .linktext2, .linktext3, .linktext4 , .linktext5, .linktext6, .linktext7, .linktext8{
    margin-top: 1.5rem;
    text-align: justify;
    line-height: 2;  
  }
  .linktext5 ul li, .linktext6 ul li, .linktext7 ul li {
    width: 100%;
  }

  .trustpilot{
    width: 45vw;
    display: block;
    margin: 0 auto;
  }
  .sublinktext {
    margin-bottom: 1.5rem;
  }
  .checkicon{
    height: 15px;
    width: 15px;
  }
  .bulletins{
    display: flex;
    align-items: center;
  }
  .bulletinhead h5 {
    margin-top: 1rem;
  }
  .bulletins p {
    margin-left: .5rem;
    margin-bottom: 0!important;
    margin-top: 0!important;
  }


  @media (min-width:320px) and (max-width:480px) {
    .Blockchainpage {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    .App-header {
      margin-left: 2rem;
      margin-right: 2rem;
      padding: 2rem;
    }
    .cbutton li a {
      width: 2%!important;
    }
}

a.no-underline {
  text-decoration: none;
}






