.service-research-div {
	padding-top: 60px;
}

.service-research-div-new {
	padding-top: 10px;
}

.service-research-number-div {
	width: 60px;
	height: 60px;
	background-color: #21e3ce;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	opacity: 0.4;
}

.service-research-h6 {
	font-size: small;
	color: #5d5d5d;
	font-weight: 600;
}

.service-research-h2 {
	color: #000;
	font-weight: 700;
	margin-bottom: 0px;
}

.service-research-div-1 {
	margin: 20px 0px;
	padding: 10px 20px 10px 0px;
}

.service-research-h4 {
	font-size: large;
	font-weight: 700;
	margin: 10px 0px;
}

.service-research-p {
	font-size: small;
	font-weight: 500;
	color: #5d5d5d;
	width: 90%;
}

/* img */

.service-bg-img-div {
	width: auto;
	height: auto;
	max-width: 90%;
	min-height: 100%;
	margin: 0px auto;
	position: relative;
}

.service-bg-img {
	width: 100%;
}

.service-bg-div {
	position: absolute;
	top: 8%;
	right: 10%;
	width: 55%;
	background-color: white;
	height: 80%;
	padding: 30px 30px;
}

.service-bg-h2 {
	font-size: 20px;
	font-weight: 800;
	width: 90%;
}

.service-bg-btn {
	border: none;
	font-size: 10px;
	border-radius: 10px;
	box-shadow: 0 15px 30px 0 rgba(20, 102, 204, 0.16);
	background-color: #ff6c1a;
	color: white;
	font-weight: 500;
	padding: 10px 30px;
	margin-top: 10px;
}

@media only screen and (max-width: 768px) {
	.service-research-number-div {
		width: 60px;
		height: 60px;
		background-color: #21e3ce;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		opacity: 0.4;
	}

	.service-research-h6 {
		font-size: small;
		color: #5d5d5d;
		font-weight: 600;
	}

	.service-research-h2 {
		color: #000;
		font-weight: 700;
		margin-bottom: 0px;
	}

	.service-research-div-1 {
		margin: 20px 0px;
		padding: 10px 20px 10px 0px;
	}

	.service-research-h4 {
		font-size: medium;
		font-weight: 700;
		margin: 10px 0px;
	}

	.service-research-p {
		font-size: x-small;
		font-weight: 500;
		color: #5d5d5d;
		width: 90%;
	}
}

@media only screen and (max-width: 576px) {
	.service-research-number-div {
		width: 60px;
		height: 60px;
		background-color: #21e3ce;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		opacity: 0.4;
	}

	.service-research-h6 {
		font-size: small;
		color: #5d5d5d;
		font-weight: 600;
	}

	.service-research-h2 {
		color: #000;
		font-weight: 700;
		margin-bottom: 0px;
	}

	.service-research-div-1 {
		margin: 10px 0px;
		padding: 0px 10px 0px 0px;
	}

	.service-research-h4 {
		font-size: medium;
		font-weight: 700;
		margin: 10px 0px;
	}

	.service-research-p {
		font-size: x-small;
		font-weight: 500;
		color: #5d5d5d;
		width: 100%;
	}
}

/* img */

@media only screen and (min-width: 1359px) {
	.service-bg-h2 {
		font-size: 24px;
		margin-top: 15%;
		font-weight: 800;
		width: 90%;
		margin-top: 30px;
	}

	.service-bg-btn {
		border: none;
		font-size: 10px;
		border-radius: 10px;
		box-shadow: 0 15px 30px 0 rgba(20, 102, 204, 0.16);
		background-color: #ff6c1a;
		color: white;
		font-weight: 500;
		padding: 10px 30px;
		margin-top: 30px;
	}
}

@media only screen and (max-width: 992px) {
	.service-bg-h2 {
		font-size: 20px;
		font-weight: 800;
		width: 90%;
	}

	.service-bg-btn {
		border: none;
		font-size: 10px;
		border-radius: 10px;
		box-shadow: 0 15px 30px 0 rgba(20, 102, 204, 0.16);
		background-color: #ff6c1a;
		color: white;
		font-weight: 500;
		padding: 10px 30px;
		margin-top: 10px;
	}
}

@media only screen and (max-width: 768px) {
	.service-bg-h2 {
		font-size: 14px;
		font-weight: 800;
		width: 90%;
	}

	.service-bg-btn {
		border: none;
		font-size: 9px;
		border-radius: 10px;
		box-shadow: 0 15px 30px 0 rgba(20, 102, 204, 0.16);
		background-color: #ff6c1a;
		color: white;
		font-weight: 500;
		padding: 6px 20px;
		margin-top: 10px;
	}
}

@media only screen and (max-width: 576px) {
	.service-bg-div {
		position: absolute;
		top: 10%;
		right: 10%;
		width: 55%;
		background-color: white;
		height: 80%;
		padding: 15px 20px;
	}

	.service-bg-h2 {
		font-size: 12px;
		font-weight: 800;
		width: 90%;
		margin-top: 7px;
	}

	.service-bg-btn {
		border: none;
		font-size: 9px;
		border-radius: 10px;
		box-shadow: 0 15px 30px 0 rgba(20, 102, 204, 0.16);
		background-color: #ff6c1a;
		color: white;
		font-weight: 500;
		padding: 4px 10px;
		margin-top: 10px;
	}
	.service-bg-img-div {
		width: auto;
		height: auto;
		max-width: 100%;
		min-height: 100%;
		margin: 0px auto;
		position: relative;
	}
}

@media only screen and (max-width: 510px) {
	.service-bg-div {
		position: absolute;
		top: 10%;
		right: 10%;
		width: 55%;
		background-color: white;
		height: 80%;
		padding: 7px 15px;
	}

	.service-bg-h2 {
		font-size: 9px;
		font-weight: 800;
		width: 90%;
		margin-top: 7px;
	}

	.service-bg-btn {
		border: none;
		font-size: 6px;
		border-radius: 10px;
		box-shadow: 0 15px 30px 0 rgba(20, 102, 204, 0.16);
		background-color: #ff6c1a;
		color: white;
		font-weight: 500;
		padding: 4px 10px;
		margin-top: 6px;
	}
}

@media only screen and (max-width: 362px) {
	.service-bg-div {
		position: absolute;
		top: 10%;
		right: 10%;
		width: 55%;
		background-color: white;
		height: 80%;
		padding: 7px 15px;
	}

	.service-bg-h2 {
		font-size: 7px;
		font-weight: 800;
		width: 90%;
		margin-top: 5px;
	}

	.service-bg-btn {
		border: none;
		font-size: 6px;
		border-radius: 10px;
		box-shadow: 0 15px 30px 0 rgba(20, 102, 204, 0.16);
		background-color: #ff6c1a;
		color: white;
		font-weight: 500;
		padding: 3px 10px;
		margin-top: 2px;
	}
}
