.web-discuss-p {
  font-weight: 600;
  width: 60%;
}

.web-discuss-btn {
  text-decoration: none;
  border: 1px solid rgba(255, 108, 26, 0.7);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 30px;
  border-radius: 9px;
  background-color: white;
  margin: 0px auto;
}

.web-discuss-btn:hover {
  text-decoration: none;
  border: 1px solid white;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 30px;
  border-radius: 9px;
  background-color: white;
  transition: 0.3s;
  box-shadow: 0 0 20px 1px white;
  transition: 0.5s;
}

@media screen and (max-width: 1200px) {
  .web-discuss-p {
    font-weight: 600;
    width: 90%;
  }
}
