.Blockchainpage {
    background-color: rgb(237, 234, 234);
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .App-header {
    margin-left: 5rem;
    margin-right: 5rem;
    padding: 3.5rem;
    background-color: white;
  }
  .content {
      line-height: 1.5;
  }
  .chead{
      margin-top: 1rem;
      
  }
  .ctext{
      margin-top: 1rem;
      text-align: justify;
      line-height: 2;
  }
  .cbutton {
    content: " ";
    height: auto;
    margin-top: .5rem;
    padding: .8rem;
    display: block;
    border: #e398b1e6 solid 1px;
    background-color: #e3c5cfe6;
  }
  .cbutton ::after{
      padding: 0.5rem;
      margin-top: 1rem;
      height: auto;
  }
  .cbutton ol li a {
    color: black;
  } 
  .cbutton ol li a:hover {
    transition: 2s;
  } 
  
  .btntxt {
    border: none;
    background: none;
  }
  
    li {
      display: block;
      margin-top: .5rem;
      /* width: 90vw; */
    }
    
    .linktext, .linktext1, .linktext2, .linktext3, .linktext4 {
      margin-top: 1.5rem;
      text-align: justify;
      line-height: 2;  
    }

  
  
    @media (min-width:320px) and (max-width:480px) {
      .Blockchainpage {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
      .App-header {
        margin-left: 2rem;
        margin-right: 2rem;
        padding: 2rem;
      }
      .cbutton li a {
        width: 2%!important;
      }
      .content , .linktext {
        font-size: 0.8rem;
        text-align: left;
      }
      .content h2 , .linktext h2, .linktext h3{
        font-size: 1rem;
        text-align: left;
      }
      .linktext h5 {
        font-size: 0.8rem;
        font-weight: bold;
        text-align: left;
      } 
      .linktext h4 {
        font-size: 0.8rem;
        text-align: left;
      } 
      .cbutton ol {
        padding-left: 0 ;
      }
      .linktext ul {
        padding-left: 0 ;
      }
  }