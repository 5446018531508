
/* section 1 */
.culture-career-1 {
  margin-top: 50px;
}

.culture-career-span {
  width: 50px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.culture-career-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 3.5rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.culture-career-1-h2 {
  font-size: 2.3rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.culture-career-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 10px 60px;
  font-size: x-small;
  font-weight: 600;
}


/* content */


.culture-career-img-div {
  width: auto;
  height: auto;
  max-width: 150px;
  max-height: 150px;
}

.culture-career-img {
  width: 100%;
  height: auto;
  max-width: 51px;
  margin-bottom: 10px;
}

.culture-career-h3 {
  font-size: large;
  font-weight: 700;
}

.culture-career-p {
  font-size: 12px;
  font-weight: 400;
  color: #5d5d5d;
  width: 80%;
}

.culture-career-div-1 {
  display: flex;
  justify-content: flex-start;
}

.career-lottie-div {
  width: auto;
  height: auto;
  max-width: 300px;
  height: 300px;
  margin: 70px 0px;
}

@media only screen and (max-width: 1200px) {
  .career-lottie-div {
  width: auto;
  height: auto;
  max-width: 250px;
  height: 300px;
  margin: 80px 0px;
}

.culture-career-p {
  font-size: 12px;
  font-weight: 400;
  color: #5d5d5d;
  width: 95%;
}
}


@media only screen and (max-width: 992px) {
  .career-lottie-div {
  width: auto;
  height: auto;
  max-width: 300px;
  height: 300px;
  margin: 30px auto;
}

.culture-career-p {
  font-size: 10px;
  font-weight: 400;
  color: #5d5d5d;
  width: 85%;
  margin-left: 10px;
}

.culture-career-h3 {
  font-size: medium;
  font-weight: 700;
  margin-left: 10px;
}

.culture-career-img-div {
  width: auto;
  height: auto;
  max-width: 150px;
  max-height: 150px;
}

.culture-career-img {
  width: 100%;
  height: 100%;
}


.culture-career {
  display: flex;
}
}

@media only screen and (max-width: 576px) {
  
/* section 1 */
.culture-career-1 {
  margin-top: 30px;
}

.culture-career-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.culture-career-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 3rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.culture-career-1-h2 {
  font-size: 1.8rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.culture-career-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 10px 30px;
  font-size: x-small;
  font-weight: 600;
}





  .career-lottie-div {
  width: auto;
  height: auto;
  max-width: 200px;
  height: 200px;
  margin: 20px auto;
}

.culture-career-p {
  font-size: 10px;
  font-weight: 400;
  color: #5d5d5d;
  width: 90%;
  margin-left: 10px;
}

.culture-career-h3 {
  font-size: medium;
  font-weight: 700;
  margin-left: 10px;
}

.culture-career-img-div {
  width: auto;
  height: auto;
  max-width: 100px;
  max-height: 100px;
}

.culture-career-img {
  width: 100%;
  height: 100%;
}


.culture-career {
  display: flex;
}
}