.ai-tech-section {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 20px 0px;
}


.ai-tech-h1 {
  font-size: 34px;
  font-weight: 700;
  width: 70%;
  margin: 20px 0px;
}

.ai-tech-li {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.ai-tech-icon {
  font-size: 18px;
  font-weight: 800 !important;
  color: tomato;
}

.ai-tech-p {
  margin-bottom: 0px;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 700;
}

.ai-tech-lottie-div {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .ai-tech-h1 {
  font-size: 34px;
  font-weight: 700;
  width: 100%;
  margin: 20px 0px;
}

}

@media screen and (max-width: 570px) {
  .ai-tech-h1 {
  font-size: 25px;
  font-weight: 700;
  width: 100%;
  margin: 20px 0px;
}

}