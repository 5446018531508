.work-case-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.work-case-title {
  font-size: 25px;
  margin: 30px 0 30px 10px;
  font-weight: bold;
  letter-spacing: 0.1px;
}

.work-case-img-div {
  width: 100%;
  height: auto;
  /* background-color: #21e3ce; */
}

.work-case-img{
  padding: 10px;
  width: 100%;
}

.work-case-h6 {
  font-size: 12px;
  margin-left: 10px;
  font-weight: bold;
}

.work-case-p {
  font-size: 10px;
  margin-left: 10px;
  color: #666666;
}


@media only screen and (max-width: 576px) {
  
.work-case-title {
  font-size: 18px;
  margin: 20px 0 20px 10px;
  font-weight: bold;
  letter-spacing: 0.1px;
}
}