.career-hire-section  {
  width: 100%;
  height: auto;
  background-color: #f8f9fa;
  margin: 20px 0px;
}

.career-hire-content {
  padding: 30px 100px;
}

.career-hire-h1 {
  font-size: x-large;
  font-weight: 700;
}

.career-hire-img-div {
  width: auto;
  height: auto;
  max-width: 50px;
  max-height: 50px;
}
.career-hire-img {
  width: 100%;
  height: 100%;
}

.career-hire-inner-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  margin: 20px 0px;
}

.career-hire-card {
   border-radius: 10px !important;
   border: none !important;
   padding: 20px 30px !important;
  background-color: #fff !important;
  width: 270px;
  margin: 20px 0px;
}

.career-hire-h2 {
  color: #21e3ce;
  font-size: 30px;
  font-weight: 600;
}

.career-hire-h3 {
  font-size: medium;
  font-weight: 600;
  margin: 20px 0px 10px 0px;
}

.career-hire-p {
  font-size: small;
  font-weight: 500;
  width: 80%;
}


@media only screen and (max-width: 1300px) {
  .career-hire-card {
  border-radius: 10px !important;
  border: none !important;
  padding: 10px 20px !important;
  background-color: #fff !important;
  width: 200px;
  margin: 20px 0px;
}


.career-hire-h3 {
  font-size: 15px;
  font-weight: 600;
  margin: 20px 0px 10px 0px;
}

.career-hire-p {
  font-size: 12px;
  font-weight: 500;
  width: 100%;
}
}

@media only screen and (max-width: 1023px) {
  .career-hire-card {
  border-radius: 10px !important;
  border: none !important;
  padding: 10px 20px !important;
  background-color: #fff !important;
  width: 190px;
  height: 220px !important;
  margin: 20px 0px;
}


.career-hire-h3 {
  font-size: 14px;
  font-weight: 600;
  margin: 20px 0px 10px 0px;
}

.career-hire-p {
  font-size: 10px;
  font-weight: 500;
  width: 100%;
}
}


@media only screen and (max-width: 992px) {
  .career-hire-card {
  border-radius: 10px !important;
  border: none !important;
  padding: 10px 20px !important;
  background-color: #fff !important;
  width: 250px;
  height: 250px !important;
  margin: 20px auto;
}


.career-hire-h3 {
  font-size: 15px;
  font-weight: 600;
  margin: 30px 0px 10px 0px;
}

.career-hire-p {
  font-size: 12px;
  font-weight: 500;
  width: 100%;
}
}


@media only screen and (max-width: 768px) {
  .career-hire-content {
  padding: 30px 20px;
}
}