


.service-nav-ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 70px;
  position: sticky;
  background-color: white;
  padding: 40px 30px 10px 30px;
  z-index: 1;
}

.product-nav-ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 70px;
  position: sticky;
  background-color: white;
  padding: 40px 30px 10px 30px;
  z-index: 1;
}



.service-nav-li {
  list-style-type: none;
  text-decoration: none;
}


.service-nav-li h6{
  text-decoration: none;
  font-size: 30px;
  font-weight: 700;
 transition: 0.3s;
  color: rgba(0, 0, 0, 0.2);
}

.service-nav-li h6:hover{
  text-decoration: none;
  color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.service-nav-li.active-scroll-spy  .service-nav-line{
 width: 60%;
 height: 2px;
 background-color:#21e3ce;
 transition: 0.3s;
} 


.service-nav-li.active-scroll-spy:hover .service-nav-line{
 width: 100%;
 height: 2px;
 transition: 0.3s;
 background-color:#21e3ce;
} 


.service-nav-li.active-scroll-spy h6{
  color: #21e3ce;
 transition: 0.3s;
}


@media only screen and (max-width: 1200px) {
  


.service-nav-li h6{
  text-decoration: none;
  font-size: 30px;
  font-weight: 700;
 transition: 0.3s;
  color: rgba(0, 0, 0, 0.2);
}

}


@media only screen and (max-width: 850px) {
.service-nav-li h6{
  text-decoration: none;
  font-size: 22px;
  font-weight: 700;
 transition: 0.3s;
  color: rgba(0, 0, 0, 0.2);
}

.service-nav-ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 60px;
  position: sticky;
  background-color: white;
  padding: 10px 20px;
  z-index: 1;
}

.product-nav-ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 60px;
  position: sticky;
  background-color: white;
  padding: 20px 30px;
  z-index: 1;
}

}


@media only screen and (max-width: 630px) {
.service-nav-li h6{
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
 transition: 0.3s;
  color: rgba(0, 0, 0, 0.2);
}

}


@media only screen and (max-width: 500px) {
.service-nav-li h6{
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
 transition: 0.3s;
  color: rgba(0, 0, 0, 0.2);
}

.service-nav-ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 50px;
  position: sticky;
  background-color: white;
  padding: 10px 20px;
  z-index: 1;
}

.product-nav-ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 50px;
  position: sticky;
  background-color: white;
  padding: 20px 30px;
  z-index: 1;
}

}


@media only screen and (max-width: 367px) {
.service-nav-li h6{
  text-decoration: none;
  font-size: 11px;
  font-weight: 700;
 transition: 0.3s;
  color: rgba(0, 0, 0, 0.2);
}

.service-nav-ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 50px;
  position: sticky;
  background-color: white;
  padding: 10px 15px;
  z-index: 1;
}

.product-nav-ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 50px;
  position: sticky;
  background-color: white;
  padding: 20px 30px;
  z-index: 1;
}

}

