
.web-about-span {
  width: 50px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.web-about-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.web-about-content-1-h2 {
  font-size: 2rem;  
  font-weight: bold;
  margin-top: -1rem;
  width: 80%;
  max-width: 100%;
}

.web-about-content-1-p{
  text-align: left;
  width: 90%;
  color: #5d5d5d;
  font-size: .8rem;
  margin: 20px 0;
}

.web-content-2-btn {
  width: 16%;
  padding: 0.3rem .7rem;
  background-image: linear-gradient(to left, #f55047, #fc8459);
  padding: 0.6rem .5rem;
  border-radius: 6px;
  border:none;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  color: #fff;
  margin: 20px 0 20px 0;
  box-shadow: 0 15px 30px 0 rgba(20, 102, 204, 0.16);
}

.web-about-lottie {
  width: 80%;
  margin: 0px auto;
}

@media screen and (max-width: 1200px) {
  
.web-about-span {
  width: 50px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.web-about-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.5rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.web-about-content-1-h2 {
  font-size: 1.6rem;  
  font-weight: bold;
  margin-top: -1rem;
  width: 80%;
  max-width: 100%;
}

.web-about-content-1-p{
  text-align: left;
  width: 90%;
  color: #5d5d5d;
  font-size: .7rem;
  margin: 10px 0;
}

.web-content-2-btn {
  width: 26%;
  padding: 0.1rem .7rem;
  background-image: linear-gradient(to left, #f55047, #fc8459);
  padding: 0.6rem .5rem;
  border-radius: 6px;
  border:none;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  color: #fff;
  margin: 10px 0 20px 0;
}

.web-about-lottie {
  width: 100%;
  margin: 0px auto;
}
}


@media screen and (max-width: 992px) {
  
.web-about-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.web-about-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.1rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.web-about-content-1-h2 {
  font-size: 1.3rem;  
  font-weight: bold;
  margin-top: -1rem;
  width: 80%;
  max-width: 100%;
}

.web-about-content-1-p{
  text-align: left;
  width: 90%;
  color: #5d5d5d;
  font-size: .6rem;
  margin: 10px 0;
}

.web-content-2-btn {
  width: 26%;
  background-image: linear-gradient(to left, #f55047, #fc8459);
  padding: 0.4rem .3rem;
  border-radius: 6px;
  border:none;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  color: #fff;
  margin: 10px 0 20px 0;
}

.web-about-lottie {
  width: 80%;
  margin: 0px auto;
}
}


@media screen and (max-width: 768px) {

  
.web-about-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.web-about-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.web-about-content-1-h2 {
  font-size: 1.3rem;  
  font-weight: bold;
  margin-top: -1rem;
  width: 95%;
  max-width: 100%;
}

.web-about-content-1-p{
  text-align: left;
  width: 100%;
  color: #5d5d5d;
  font-size: .6rem;
  margin: 10px 0;
}

.web-content-2-btn {
  width: 26%;
  background-image: linear-gradient(to left, #f55047, #fc8459);
  padding: 0.4rem .3rem;
  border-radius: 6px;
  border:none;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  color: #fff;
  margin: 10px 0 20px 0;
}

.web-about-lottie {
  width: 100%;
  height: 80%;
  margin: auto;
}
}


@media screen and (max-width: 400px) {



.web-about-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 1.8rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.web-about-content-1-h2 {
  font-size: 1.1rem;  
  font-weight: bold;
  margin-top: -1rem;
  width: 95%;
  max-width: 100%;
}

.web-about-content-1-p{
  text-align: left;
  width: 90%;
  color: #5d5d5d;
  font-size: .6rem;
  margin: 10px 0;
}

.web-about-lottie {
  width: 100%;
  height: 80%;
  margin: auto;
}
}