.about-home-container {
    width: 100%;
    height: 100%;
  }
  
  .about-home-img {
    filter: brightness(0.4);
    width: 100%;
    height: 100%;
  }
  
  .about-home-div-1 {
    position: relative;
  }
  
  .about-home-h1-div {
    position: absolute;
    top: 35%;
  }
  .about-home-h1 {
    color: white;
    text-align: center;
    font-weight: bold;
  }
  
  .about-home-span {
    color: #ff6c1a;
  }
  
  .about-home-p-div {
    position: absolute;
    bottom: 5%;
  }
  
  .about-home-p {
    font-size: 12px;
    color: white;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
  }
  
  .about-home-p:hover {
    font-size: 12px;
    color: #ff6c1a;
    font-weight: 600;
    text-align: center;
    transition: 0.3s;
  }
  
  
  @media only screen and (max-width: 768px) {
    .about-home-h1 {
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
  }
  .about-home-h1-div {
    position: absolute;
    top: 30%;
  }
  
  .about-home-p-div {
    position: absolute;
    bottom: 1%;
  }
  
  
  .about-home-p {
    font-size: 9px;
    color: white;
    font-weight: 600;
    text-align: center;
  }
  
  .about-home-p:hover {
    color: #ff6c1a;
    font-size: 9px;
    font-weight: 600;
    text-align: center;
  }
  
  }
  
  
  @media only screen and (max-width: 576px) {
    .about-home-h1 {
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }
  .about-home-h1-div {
    position: absolute;
    top: 35%;
  }
  
  .about-home-p-div {
    position: absolute;
    bottom: 1%;
  }
  
  
  .about-home-p {
    font-size: 7px;
    color: white;
    font-weight: 600;
    text-align: center;
  }
  
  .about-home-p:hover {
    color: #ff6c1a;
    font-size: 7px;
    font-weight: 600;
    text-align: center;
  }
  
  }
  
  @media only screen and (max-width: 400px) {
    .about-home-h1 {
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
  }
  .about-home-h1-div {
    position: absolute;
    top: 35%;
  }
  
  .about-home-p-div {
    position: absolute;
    bottom: 0%;
  }
  
  
  .about-home-p {
    font-size: 5px;
    color: white;
    font-weight: 600;
    text-align: center;
  }
  
  .about-home-p:hover {
    color: #ff6c1a;
    font-size: 5px;
    font-weight: 600;
    text-align: center;
  }
  
  }