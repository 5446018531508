
.web-service-span {
  width: 50px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.web-service-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.web-service-content-1-h2 {
  font-size: 2rem;  
  font-weight: bold;
  margin-top: -1rem;
  width: 80%;
  max-width: 100%;
}

@media screen and (max-width: 1200px) {
  
.web-service-span {
  width: 50px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.web-service-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.6rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.web-service-content-1-h2 {
  font-size: 1.8rem;  
  font-weight: bold;
  margin-top: -1rem;
  width: 80%;
  max-width: 100%;
}
}

@media screen and (max-width: 768px) {
  
.web-service-span {
  width: 40px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.web-service-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.3rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.web-service-content-1-h2 {
  font-size: 1.4rem;  
  font-weight: bold;
  margin-top: -1rem;
  width: 80%;
  max-width: 100%;
}
}

@media screen and (max-width: 576px) {
  
.web-service-span {
  width: 40px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.web-service-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 1.5rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.web-service-content-1-h2 {
  font-size: 1.2rem;  
  font-weight: bold;
  margin-top: -.8rem;
  width: 80%;
  max-width: 100%;
}
}