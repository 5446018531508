.about-about-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.about-about-content-1 {
  margin-top: 90px;
  margin-left: 50px;
}

.about-about-span {
  width: 50px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-about-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 3rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.about-about-content-1-h2 {
  font-size: 2rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.about-about-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 20px 0;
  font-size: .9rem;
}

.about-about-content-1-p{
  text-align: justify;
  width: 90%;
  color: #5d5d5d;
  font-size: .9rem;
  margin: 40px 0;
}

/* content - 2 */


.about-about-content-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 20px;
  margin-left: 50px;
}

.about-about-content-2-btn {
  width: 16%;
  padding: 0.3rem .7rem;
  background-image: linear-gradient(to left, #f55047, #fc8459);
  padding: 0.6rem .5rem;
  border-radius: 6px;
  border:none;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  color: #fff;
  margin: 0px 0 20px 0;
}

.about-about-content-2-h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10 px;
}

.about-about-content-2-p {
  font-size: 15px;
  font-weight: 600;
}


/* About section 2 */

.about-about-content-3 {
  margin-top: 80px;
  border-radius: 50%;
  width: 100%;
  height: 90%;
}
.about-about-img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}


@media only screen and (max-width: 1100px) {

  .about-about-content-1 {
  margin-top: 70px;
  margin-left: 50px;
}

.about-about-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-about-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.5rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.about-about-content-1-h2 {
  font-size: 1.7rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.about-about-content-1-p{
  text-align: justify;
  width: 85%;
  color: #5d5d5d;
  font-size: .6rem;
  margin: 40px 0;
}

/* content - 2 */


.about-about-content-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  margin-bottom: 20px;
  margin-left: 50px;
}

.about-about-content-2-h1 {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 10 px;
}

.about-about-content-2-p {
  font-size: 12px;
  font-weight: 600;
}


/* About section 2 */

.about-about-content-3 {
  margin-top: 80px;
}
.about-about-img {
  width: 100%;
  height: 100%;
}

}

@media only screen and (max-width: 830px) {

  .about-about-content-1 {
  margin-top: 50px;
  margin-left: 50px;
}

.about-about-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-about-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.2rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.about-about-content-1-h2 {
  font-size: 1.5rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.about-about-content-1-p{
  text-align: justify;
  width: 85%;
  color: #5d5d5d;
  font-size: .5rem;
  margin: 15px 0;
}

/* content - 2 */


.about-about-content-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  margin-bottom: 20px;
  margin-left: 50px;
}

.about-about-content-2-h1 {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 10 px;
}

.about-about-content-2-p {
  font-size: 11px;
  font-weight: 600;
}


/* About section 2 */

.about-about-content-3 {
  margin-top: 50px;
}
.about-about-img {
  width: 100%;
  height: 100%;
}

}


@media only screen and (max-width: 768px) {

  .about-about-content-1 {
  margin-top: 30px;
  margin-left: 50px;
}



.about-about-content-1-p{
  text-align: justify;
  width: 85%;
  color: #5d5d5d;
  font-size: .5rem;
  margin: 15px 0;
}

/* content - 2 */


.about-about-content-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  margin-bottom: 0px;
  margin-left: 50px;
}

.about-about-content-2-h1 {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 10 px;
}

.about-about-content-2-p {
  font-size: 11px;
  font-weight: 600;
}


/* About section 2 */


 
.about-about-section-2 {
  display: none !important;
}
}

@media only screen and (max-width: 470px) {

  .about-about-content-1 {
  margin-top: 30px;
  margin-left: 50px;
}

.about-about-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-about-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.about-about-content-1-h2 {
  font-size: 1.3rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.about-about-content-1-p{
  text-align: justify;
  width: 85%;
  color: #5d5d5d;
  font-size: .4rem;
  margin: 15px 0;
}

/* content - 2 */


.about-about-content-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  margin-left: 50px;
}

.about-about-content-2-h1 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 3px;
}

.about-about-content-2-p {
  font-size: 8px;
  font-weight: 600;
}


/* About section 2 */

.about-about-section-2 {
  display: none !important;
}

}