.testi-container {
  width: 100%;
  height: 430px;
  background-color: #f7f7f7;
  /* margin: 0 !important; */
}

.container-fluid {
  padding: 0 !important;
}


.row {
  padding: 0 !important;
  margin: 0 !important;
}

.testi-section-1 {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testi-content-1 {
  margin-top: 20px;
}

.testi-span {
  width: 50px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.testi-content-1-h1 {
  display: flex;
  text-align: left;
  font-size: 3.5rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.testi-content-1-h2 {
  font-size: 2rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.testi-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 10px 0;
  font-size: .9rem;;
}


/* image section */

.testi-content-imgs {
  position: relative;
}

.testi-img {
  width: 19%;
  border-radius: 50%;
  border: 2px solid #adb5bd;
  transition: 0.5s;
  width: 40%;
}
.testi-img:hover {
  width: 19%;
  border-radius: 50%;
  border: 2px solid#ff6c1a;
  transition: 0.5s;
  width: 40%;
}


/* left div edit  */

.testi-div-1 {
  position: absolute;
  top: 30%;
  left: 20%; 
}

.testi-div-2 {
  position: absolute;
  top: 20%;
  left: 65%; 
}

.testi-div-3 {
  position: absolute;
  top: 60%;
  left: 64%; 
}

.testi-div-4 {
  position: absolute;
  top: 80%;
  left: 30%; 
}



.testi-div-5 {
  position: absolute;
  top: 100%;
  left: 70%; 
}

/* Right div edit */

.testi-div-6 {
  position: absolute;
  top: 15%;
  right: 65%; 
}

.testi-div-7 {
  position: absolute;
  top: 25%;
  left: 35%;
}

.testi-div-8 {
  position: absolute;
  top: 60%;
  left: 65%; 
}

.testi-div-9 {
  position: absolute;
  top: 50%;
  right: 60%; 
}

.testi-div-10 {
  position: absolute;
  top: 70%;
  right: 30%; 
}

.testi-div-11 {
  position: absolute;
  top: 100%;
  right: 6%; 
}
/* floating */

.floating {  
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}


@keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 15px); }
    to   { transform: translate(0, -0px); }    
}


/* QUOTES SECTION */

/* 
.testi-quote-content {
  width: auto;
  position: relative;
  background-color: white;
} */
.quote{
  width: 90%;
  position: relative;
  margin: 30px auto;
}

.quote-anime {
  width: 100%;
  height: 170px;
}

.quote-p {
  font-size: 14px;
  color: #495057; 
  font-weight: 400;
}

.quote-info{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.quote-name {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  font-size: 14px;
  color: #495057; 
  font-weight: bold;
}


.quote-job {
  margin-right: 20px;
  font-size: 14px;
  color: #495057; 
  font-weight: 600;
}

.pp {
  border-radius: 2px;
  width: 7px;
  height: 2px;
  margin: 11px 3px 0 0;
  background-color: #495057;
}


@media only screen and (max-width: 1222px) {
 /* left div edit  */

.testi-div-1 {
  position: absolute;
  top: 7rem;
  left: 2rem; 
}

.testi-div-2 {
  position: absolute;
  top: 3rem;
  left: 10rem; 
}

.testi-div-3 {
  position: absolute;
  top: 11rem;
  left: 9rem; 
}

.testi-div-4 {
  position: absolute;
  top: 17rem;
  left: 3rem; 
}



.testi-div-5 {
  position: absolute;
  top: 20rem;
  left: 10rem; 
}

/* Right div edit */

.testi-div-6 {
  position: absolute;
  top: 2rem;
  right: 10rem; 
}

.testi-div-7 {
  position: absolute;
  top: 4rem;
  left: 9rem
}

.testi-div-8 {
  position: absolute;
  top: 10rem;
  left: 8rem; 
}

.testi-div-9 {
  position: absolute;
  top: 8rem;
  right: 6rem; 
}

.testi-div-10 {
  position: absolute;
  top: 16rem;
  right: 1rem; 
}

.testi-div-11 {
  position: absolute;
  top: 18rem;
  right: 8rem; 
}

.testi-quote-content {
  width: 95%;
  height: 180px;
  position: relative;
}

.quote-anime {
  width: 100%;
  height: 190px;
}
.quote{
  width: 95%;
  margin: 40px auto;
}
}

@media only screen and (max-width: 992px) {
 .floating{
  display: none;
 }

 /* QUOTES SECTION */
.testi-container {
  width: 100%;
  height: 350px;
  background-color: #f7f7f7;
  margin: 0 !important;
}

.testi-quote-content {
  width: 80%;
  height: 140px;
  position: relative;
  /* overflow: hidden; */
  /* background-color: white; */
}
.quote{
  width: 80%;
  margin: 40px auto;
}

.quote-p {
  font-size: 14px;
  color: #495057;
  font-weight: 400;
}

.quote-info{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 30px ;
}

.quote-name {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  font-size: 14px;
  color: #495057; 
  font-weight: bold;
}


.quote-job {
  margin-right: 20px;
  font-size: 14px;
  color: #495057; 
  font-weight: 600;
}

.pp {
  border-radius: 2px;
  width: 7px;
  height: 2px;
  margin: 11px 3px 0 0;
  background-color: #495057;
}
}


@media only screen and (max-width: 650px) {
 .floating{
  display: none;
 }

 /* QUOTES SECTION */
.testi-container {
  width: 100%;
  height: 420px;
  background-color: #f7f7f7;
  margin: 0 !important;
}

.testi-quote-content {
  width: 80%;
  height: 200px;
  position: relative;
  /* overflow: hidden; */
  /* background-color: white; */
}


.quote-anime {
  width: 100%;
  height: 230px;
}
.quote{
  width: 80%;
  margin: 40px auto;
}

.quote-p {
  font-size: 14px;
  color: #495057;
  font-weight: 400;
}

.quote-info{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 30px ;
}

.quote-name {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  font-size: 14px;
  color: #495057; 
  font-weight: bold;
}


.quote-job {
  margin-right: 20px;
  font-size: 14px;
  color: #495057; 
  font-weight: 600;
}

.pp {
  border-radius: 2px;
  width: 7px;
  height: 2px;
  margin: 11px 3px 0 0;
  background-color: #495057;
}
}


@media only screen and (max-width: 470px) {

  /* section -1 */

  
.testi-content-1 {
  margin-top: 20px;
}

.testi-span {
  width: 50px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.testi-content-1-h1 {
  display: flex;
  text-align: left;
  font-size: 2.8rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
}

.testi-content-1-h2 {
  font-size: 1.6rem;  
  font-weight: bold;
  margin-top: -1.6rem;
}

.testi-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 10px 0;
  font-size: .7rem;;
}



/* float */
 .floating{
  display: none;
 }

 /* QUOTES SECTION */
.testi-container {
  width: 100%;
  height: 340px;
  background-color: #f7f7f7;
  margin: 0 !important;
}

.testi-quote-content {
  width: 80%;
  height: 200px;
  position: relative;
  /* overflow: hidden; */
  /* background-color: white; */
}


.quote-anime {
  width: 100%;
  height: 180px;
}
.quote{
  width: 90%;
  margin: 30px auto;
}

.quote-p {
  font-size: 11px;
  color: #495057;
  font-weight: 400;
}

.quote-info{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 30px ;
}

.quote-name {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  font-size: 13px;
  color: #495057; 
  font-weight: bold;
}


.quote-job {
  margin-right: 20px;
  font-size: 12px;
  color: #495057; 
  font-weight: 600;
}

.pp {
  border-radius: 2px;
  width: 7px;
  height: 2px;
  margin: 9px 3px 0 0;
  background-color: #495057;
}
}