
.navbar-container {
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
}

.navbar-bg-1 {
  transition: .6s;
  opacity: 1;
  background-color: white;
  
}

.menu-icon-bg {
  font-size: 2.2rem;
  margin-right: 10px; 
  color: black;
  transition: .6s;
}

.navbar-logo {
 width: 32%; 
 margin-left: 3%;
 z-index: 1;
}

.hide-menu {
  position: absolute;
  background-color: black;
  width: 50%;
  height: auto;
  min-height: 100vh;
  position: fixed;
  top: 3px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  transform: translateX(100%);
  transition: 0.5s;
  z-index: 1;

}

.show-menu {
  position: absolute;
  background-color: black;
  width: 50%;
  height: auto;
  min-height: 100vh;
  top: 3px;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  transform: translateX(0%);
  transition: 0.5s;
  z-index: 1;
}

.nav-ul {
  padding: 10px 20px;
  margin-top: 40px;
  margin-left: 70px;
}

.nav-ul li { 
  list-style: none;
  margin-bottom: 2rem;
  font-size: 2.3rem;
  font-weight: bold;
  text-align: left;
}

.nav-ul li a{
  text-decoration: none;
  color: white;
  letter-spacing: 1px;
  position: relative;
  background: black;
  overflow: hidden;
  word-break: keep-all;
  width: 0;
  transition: 0.5s width ease-in-out;

}

.nav-ul li a:hover  {
  color: #ff6c1a;
  transition: 0.3s;
}


.hamburger-menu{
 
  z-index: 2;
  font-size: 2rem;
  margin-right: 2%;
  margin-top: 20px;
}

.hamburger-menu div {
  color: white;
}

.cancel-icon:hover {
  color: white;
  /* margin-bottom: 10px; */
}

i .menu-icon-new {
  font-size: 2.2rem;
  color: black;
  margin-right: 10px; 
  transition: .6s;
}




/* responsive */


@media only screen and (min-width: 798px) {

  .navbar-container {
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
}


  .show-menu {
  position: absolute;
  width: 99%;
  margin: 5px 5px 5px 5px;
  min-height: 100vh;
  height: auto;
  top: 0rem;
  right: 0rem;
  background-color: black;
}

.hide-menu {
  position: absolute;
  background-color: black;
  width: 99%;
  margin: 5px 0px 5px 5px;
  min-height: 100vh;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
}

.navbar-logo {
 width: 42%; 
 margin-left: 3%;
 margin-top: 12px;
}


i .menu-icon-new {
  font-size: 1.8rem;
  color: black;
  margin-right: 5px;
  margin-top: -35px; 
}

.nav-ul {
  padding: 20px 40px;
  margin-top: 60px;
}

.nav-ul li {
  list-style: none;
  margin-bottom: 3rem;
  margin-left: 30px;
  font-size: 2.4rem;
  font-weight: bold;
}

.nav-ul li a {
  text-decoration: none;
  color: white;
  letter-spacing: .6px;
}

.navbar-bg-1 {
  transition: .6s;
  opacity: 1;
  background-color: white;
}

.menu-icon-bg {
   font-size: 1.8rem;
  color: black;
  margin-right: 5px;
  margin-top: -30px;
}

.cancel-icon {
  color: white;
  margin-top: -30px;
  margin-right: 3px;
  font-size: 2rem;
}


.cancel-icon:hover {
  color: white;
  margin-top: -30px;
  margin-right: 3px;
}
}

@media only screen and (min-width: 880px) {

 .navbar-container {
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
}


 .show-menu {
  position: absolute;
  width: 99%;
  min-height: 100vh;
  height: auto;
  top: 0rem;
  right: 0rem;
  background-color: black;
}

.hide-menu {
  position: absolute;
  background-color: black;
  width: 99%;
  min-height: 100vh;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
}

.navbar-logo {
 width: 37%; 
 margin-left: 3%;
 margin-top: 12px;
}

i .menu-icon-new {
  font-size: 2rem;
  color: black;
  margin-right: 5px;
  margin-top: -20px; 
}

.nav-ul {
  padding: 20px 40px;
  margin-top: 60px;
}

.nav-ul li {
  list-style: none;
  margin-bottom: 2.8rem;
  margin-left: 30px;
  font-size: 2.5rem;
  font-weight: bold;
}

.nav-ul li a {
  text-decoration: none;
  color: white;
  letter-spacing: .6px;
}

.navbar-bg-1 {
  transition: .6s;
  opacity: 1;
  background-color: white;
}

.menu-icon-bg {
  font-size: 2rem;
  color: black;
  margin-right: 5px;
  margin-top: -20px;
}

.cancel-icon {
  color: white;
  margin-top: -20px;
  margin-right: 3px;
  font-size: 2rem;
}


.cancel-icon:hover {
  color: white;
  margin-top: -20px;
  margin-right: 3px;
}
}

@media only screen and (min-width: 1030px) {

 .navbar-container {
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
}


 .show-menu {
  position: absolute;
  width: 50%;
  min-height: 100vh;
  height: auto;
  top: 0rem;
  right: 0rem;
  background-color: black;
}

.hide-menu {
  position: absolute;
  background-color: black;
  width: 100%;
  min-height: 100vh;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
}

.navbar-logo {
 width: 33%; 
 margin-left: 3%;
 margin-top: 12px;
}


i .menu-icon-new {
  font-size: 2rem;
  color: black;
  margin-right: 5px;
  margin-top: -20px; 
}

.nav-ul {
  padding: 20px 40px;
  margin-top: 60px;
}

.nav-ul li {
  list-style: none;
  margin-bottom: 2.8rem;
  margin-left: 30px;
  font-size: 2rem;
  font-weight: bold;
}

.nav-ul li a {
  text-decoration: none;
  color: white;
  letter-spacing: .6px;
}

.navbar-bg-1 {
  transition: .6s;
  opacity: 1;
  background-color: white;
}

.menu-icon-bg {
  font-size: 2rem;
  color: black;
  margin-right: 5px;
  margin-top: -20px;
}

.cancel-icon {
  color: white;
  margin-top: -20px;
  margin-right: 3px;
  font-size: 2rem;
}


.cancel-icon:hover {
  color: white;
  margin-top: -20px;
  margin-right: 3px;
}
}

@media only screen and (min-width: 1150px) {

 .navbar-container {
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
}


 .show-menu {
  position: absolute;
  width: 50%;
  min-height: 100vh;
  height: auto;
  top: 0rem;
  right: 0rem;
  background-color: black;
}

.hide-menu {
  position: absolute;
  background-color: black;
  width: 100%;
  min-height: 100vh;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
}

.navbar-logo {
 width: 30%; 
 margin-left: 3%;
 margin-top: 12px;
}



i .menu-icon-new {
  font-size: 2rem;
  color: black;
  margin-right: 5px;
  margin-top: -20px; 
}

.nav-ul {
  padding: 20px 40px;
  margin-top: 50px;
}

.nav-ul li {
  list-style: none;
  margin-bottom: 2.3rem;
  margin-left: 20px;
  font-size: 2rem;
  font-weight: bold;
}

.nav-ul li a {
  text-decoration: none;
  color: white;
  letter-spacing: .6px;
}

.navbar-bg-1 {
  transition: .6s;
  opacity: 1;
  background-color: white;
}

.menu-icon-bg {
  font-size: 2rem;
  color: black;
  margin-right: 5px;
  margin-top: -20px;
}

.cancel-icon {
  color: white;
  margin-top: -20px;
  margin-right: 3px;
  font-size: 2rem;
}


.cancel-icon:hover {
  color: white;
  margin-top: -20px;
  margin-right: 3px;
}
}

@media only screen and (min-width: 1250px) {

 .navbar-container {
  width: 100%;
  height: 75px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
}


 .show-menu {
  position: absolute;
  width: 50%;
  min-height: 100vh;
  height: auto;
  top: 0rem;
  right: 0rem;
  background-color: black;
}

.hide-menu {
  position: absolute;
  background-color: black;
  width: 50%;
  min-height: 100vh;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
}

.navbar-logo {
 width: 29%; 
 margin-left: 3%;
 margin-top: 12px;
}


i .menu-icon-new {
  font-size: 2.3rem;
  color: black;
  margin-right: 5px;
  margin-top: -20px; 
}

.nav-ul {
  padding: 20px 40px;
  margin-top: 60px;
}

.nav-ul li {
  list-style: none;
  margin-bottom: 2.8rem;
  margin-left: 20px;
  font-size: 2rem;
  font-weight: bold;
}

.nav-ul li a {
  text-decoration: none;
  color: white;
  letter-spacing: .6px;
}

.navbar-bg-1 {
  transition: .6s;
  opacity: 1;
  background-color: white;
}

.menu-icon-bg {
  font-size: 2.3rem;
  color: black;
  margin-right: 5px;
  margin-top: -20px;
}

.cancel-icon {
  color: white;
  margin-top: -20px;
  margin-right: 3px;
  font-size: 2rem;
}


.cancel-icon:hover {
  color: white;
  margin-top: -20px;
  margin-right: 3px;
}
}

@media only screen and (min-width: 1400px) {

 .navbar-container {
  width: 100%;
  height: 75px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
}


 .show-menu {
  position: absolute;
  width: 50%;
  min-height: 100vh;
  height: auto;
  top: 0rem;
  right: 0rem;
  background-color: black;
}

.hide-menu {
  position: absolute;
  background-color: black;
  width: 50%;
  min-height: 100vh;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
}

.navbar-logo {
 width: 28%; 
 margin-left: 3%;
 margin-top: 9px;
}


i .menu-icon-new {
  font-size: 2.3rem;
  color: black;
  margin-right: 5px;
  margin-top: -20px; 
}

.nav-ul {
  padding: 30px 40px;
  margin-top: 60px;
}

.nav-ul li {
  list-style: none;
  margin-bottom: 2.3rem;
  margin-left: 20px;
  font-size: 2.5rem;
  font-weight: bold;
}

.nav-ul li a {
  text-decoration: none;
  color: white;
  letter-spacing: .6px;
}

.navbar-bg-1 {
  transition: .6s;
  opacity: 1;
  background-color: white;
}

.menu-icon-bg {
  font-size: 2.3rem;
  color: black;
  margin-right: 5px;
  margin-top: -20px;
}

.cancel-icon {
  color: white;
  margin-top: -20px;
  margin-right: 3px;
  font-size: 2rem;
}


.cancel-icon:hover {
  color: white;
  margin-top: -20px;
  margin-right: 3px;
}
}

@media only screen and (min-width: 1550px) {

 .navbar-container {
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
}


 .show-menu {
  position: absolute;
  width: 50%;
  min-height: 100vh;
  height: auto;
  top: 0rem;
  right: 0rem;
  background-color: black;
}

.hide-menu {
  position: absolute;
  background-color: black;
  width: 50%;
  min-height: 100vh;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
}

.navbar-logo {
 width: 27%; 
 margin-left: 3%;
 margin-top: 10px;
}


i .menu-icon-new {
  font-size: 2.5rem;
  color: black;
  margin-right: 5px;
  margin-top: -15px; 
}

.nav-ul {
  padding: 20px 40px;
  margin-top: 60px;
}

.nav-ul li {
  list-style: none;
  margin-bottom: 2.8rem;
  margin-left: 20px;
  font-size: 2rem;
  font-weight: bold;
}

.nav-ul li a {
  text-decoration: none;
  color: white;
  letter-spacing: .6px;
}

.navbar-bg-1 {
  transition: .6s;
  opacity: 1;
  background-color: white;
}

.menu-icon-bg {
  font-size: 2.5rem;
  color: black;
  margin-right: 5px;
  margin-top: -15px;
}

.cancel-icon {
  color: white;
  margin-top: -20px;
  margin-right: 3px;
  font-size: 2.5rem;
}


.cancel-icon:hover {
  color: white;
  margin-top: -20px;
  margin-right: 3px;
}
}


@media only screen and (min-width: 1750px) {

 .navbar-container {
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
}


 .show-menu {
  position: absolute;
  width: 50%;
  min-height: 100vh;
  height: auto;
  top: 0rem;
  right: 0rem;
  background-color: black;
}

.hide-menu {
  position: absolute;
  background-color: black;
  width: 50%;
  min-height: 100vh;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
}

.navbar-logo {
 width: 25%; 
 margin-left: 3%;
 margin-top: 8px;
}


i .menu-icon-new {
  font-size: 2.5rem;
  color: black;
  margin-right: 5px;
  margin-top: -15px; 
}

.nav-ul {
  padding: 20px 40px;
  margin-top: 60px;
}

.nav-ul li {
  list-style: none;
  margin-bottom: 2.8rem;
  margin-left: 20px;
  font-size: 2rem;
  font-weight: bold;
}

.nav-ul li a {
  text-decoration: none;
  color: white;
  letter-spacing: .6px;
}

.navbar-bg-1 {
  transition: .6s;
  opacity:1;
  background-color: white;
}

.menu-icon-bg {
  font-size: 2.5rem;
  color: black;
  margin-right: 5px;
  margin-top: -15px;
}

.cancel-icon {
  color: white;
  margin-top: -20px;
  margin-right: 3px;
  font-size: 2.5rem;
}


.cancel-icon:hover {
  color: white;
  margin-top: -20px;
  margin-right: 3px;
}
}



/* max width */

@media only screen and (max-width: 798px) {

  .navbar-container {
  width: 100%;
  height: 65px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
}


  .show-menu {
  position: absolute;
   width: 99%;
  margin: 5px 5px 5px 5px;
  min-height: 100vh;
  height: auto;
  top: 0rem;
  right: 0rem;
  background-color: black;
}

.hide-menu {
  position: absolute;
  background-color: black;
  min-height: 100vh;
  width: 99%;
  margin: 5px 0px 5px 5px;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
}

.navbar-logo {
 width: 42%; 
 margin-left: 3%;
 margin-top: 12px;
}



i .menu-icon-new {
  font-size: 1.8rem;
  color: black;
  margin-right: 5px;
  margin-top: -35px; 
}

.nav-ul {
  padding: 20px 40px;
  margin-top: 60px;
}

.nav-ul li {
  list-style: none;
  margin-bottom: 3.2rem;
  margin-left: 30px;
  font-size: 2rem;
  font-weight: bold;
}

.nav-ul li a {
  text-decoration: none;
  color: white;
  letter-spacing: .6px;
}

.navbar-bg-1 {
  transition: .6s;
  opacity: 1;
  background-color: white;
}

.menu-icon-bg {
   font-size: 1.8rem;
  color: black;
  margin-right: 5px;
  margin-top: -30px;
}

.cancel-icon {
  color: white;
  margin-top: -30px;
  margin-right: 3px;
  font-size: 2rem;
}


.cancel-icon:hover {
  color: white;
  margin-top: -30px;
  margin-right: 3px;
}
}

@media only screen and (max-width: 670px) {
  
.navbar-container {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
} 

.show-menu {
  position: absolute;
  width: 98.5%;
  min-height: 100vh;
  height: auto;
  top: 0rem;
  right: 0rem;
  background-color: black;
}

.hide-menu {
  position: absolute;
  background-color: black;
  width: 98.5%;
  min-height: 100vh;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
}

.navbar-logo {
 width: 45%; 
 margin-left: 3%;
 margin-top: 10px;
}




.nav-ul {
  padding: 20px 40px;
  margin-top: 30px;
}

.nav-ul li {
  list-style: none;
  margin-bottom: 1.1rem;
  margin: 45px auto;
  font-size: 2.4rem;
  font-weight: bold;
}

.nav-ul li a {
  text-decoration: none;
  color: white;
  letter-spacing: .6px;
}

.hamburger-menu{
  z-index: 2;
  font-size: 2.8rem;
  margin-right: 10px;
  margin-top: 3px;
}

.navbar-bg-1 {
  transition: .6s;
  opacity: 1;
  background-color: white;
}

.menu-icon-bg {
  font-size: 1.7rem;
  color: black;
}



.cancel-icon {
  color: white;
  margin-top: -10px;
  margin-right: 10px;
  font-size: 1.5rem;
}


.cancel-icon:hover {
  color: white;
  margin-top: -40px;
  margin-right: 5px;
}

}

@media only screen and (max-width: 570px) {

  .navbar-container {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
}

.show-menu {
  position: absolute;
  width: 98%;
  min-height: 100vh;
  height: auto;
  top: 0rem;
  right: 0rem;
  background-color: black;
}

.hide-menu {
  position: absolute;
  background-color: black;
  min-height: 100vh;
  width: 98%;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
}

.navbar-logo {
 width: 50%; 
 margin-left: 3%;
 margin-top: 12px;
}




.nav-ul {
  padding: 20px 10px;
  margin-top: 30px;
}

.nav-ul li {
  list-style: none;
  margin-bottom: 1.1rem;
  margin: 45px auto;
  font-size: 2.4rem;
  font-weight: bold;
}

.nav-ul li a {
  text-decoration: none;
  color: white;
  letter-spacing: .6px;
}

.hamburger-menu{
  z-index: 2;
  font-size: 2.8rem;
  margin-right: 10px;
  margin-top: 5px;
}

.navbar-bg-1 {
  transition: .6s;
  opacity: 1;
  background-color: white;
}

.menu-icon-bg {
  font-size: 1.7rem;
  color: black;
}



.cancel-icon {
  color: white;
  margin-top: -20px;
  margin-right: 10px;
  font-size: 1.5rem;
}


.cancel-icon:hover {
  color: white;
  margin-top: -40px;
  margin-right: 5px;
}


}

@media only screen and (max-width: 475px) {

.navbar-container {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
}

.show-menu {
  position: absolute;
  width: 98%;
  margin: 5px 5px 5px 5px;
  min-height: 100vh;
  height: auto;
  top: 0rem;
  right: 0rem;
  background-color: black;
}

.hide-menu {
  position: absolute;
  background-color: black;
  min-height: 100vh;
  width: 98%;
  margin: 5px 0px 5px 5px;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
}

.navbar-logo {
 width: 55%; 
 margin-left: 3%;
 margin-top: 15px;
}


.nav-ul {
  padding: 20px 40px;
  margin-top: 30px;
}

.nav-ul li {
  list-style: none;
  margin-bottom: 2.2rem;
  margin: 43px auto;
  font-size: 2.5rem;
  font-weight: bold;
}

.nav-ul li a {
  text-decoration: none;
  color: white;
  letter-spacing: .6px;
}

.hamburger-menu{
  z-index: 2;
  font-size: 1.4rem;
  margin-right: 10px;
  margin-top: 26px;
}

.navbar-bg-1 {
  transition: .6s;
  opacity: 1;
  background-color: white;
}

.menu-icon-new {
  font-size: 1.4rem;
  margin-right: 10px; 
  color: black;
}

.menu-icon-bg {
  font-size: 1.7rem;
  margin-right: 5px; 
  color: black;
}



.cancel-icon {
  color: white;
  margin-top: -30px;
  margin-right: 8px;
  font-size: 1.5rem;
}


.cancel-icon:hover {
  color: white;
  margin-top: -30px;
  margin-right: 8px;
}

}

@media only screen and (max-width: 425px) {

.navbar-container {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
}

.show-menu {
  position: absolute;
  width: 98%;
  margin: 5px 5px 5px 5px;
  height: auto;
  top: 0rem;
  min-height: 100vh;
  right: 0rem;
  background-color: black;
}

.hide-menu {
  position: absolute;
  background-color: black;
  width: 98%;
  margin: 5px 0px 5px 5px;
  min-height: 100vh;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
}

.navbar-logo {
 width: 55%; 
 margin-left: 3%;
 margin-top: 15px;
}


.nav-ul {
  padding: 20px 40px;
  margin-top: 30px;
}

.nav-ul li {
  list-style: none;
  /* margin-bottom: 2rem; */
  margin: 48px auto;
  font-size: 2.2rem;
  font-weight: bold;
}

.nav-ul li a {
  text-decoration: none;
  color: white;
  letter-spacing: .6px;
}

.hamburger-menu{
  z-index: 2;
  font-size: 2rem;
  margin-right: 10px;
  margin-top: 20px;
}

.navbar-bg-1 {
  transition: .6s;
  opacity: 1;
  background-color: white;
}

.menu-icon-bg {
  font-size: 1.8rem;
  margin-right: 5px; 
  color: black;
}



.cancel-icon {
  color: white;
  margin-top: -40px;
  margin-right: 5px;
  font-size: 1.6rem;
}


.cancel-icon:hover {
  color: white;
  margin-top: -40px;
  margin-right: 5px;
}

}

@media only screen and (max-width: 365px) {

.navbar-container {
  width: 100%;
  height: 55px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
}

.show-menu {
  position: absolute;
  width: 98%;
  min-height: 100vh;
  height: auto;
  top: 0rem;
  right: 0rem;
  background-color: black;
}

.hide-menu {
  position: absolute;
  background-color: black;
  width: 98%;
  min-height: 100vh;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
}

.navbar-logo {
 width: 60%; 
 margin-left: 3%;
 margin-top: 14px;
}



.nav-ul {
  padding: 20px 10px;
  margin-top: 30px;
}

.nav-ul li {
  list-style: none;
  margin-bottom: 2rem;
  margin: 45px auto;
  font-size: 2rem;
  font-weight: bold;
}

.nav-ul li a {
  text-decoration: none;
  color: white;
  letter-spacing: .6px;
}

.hamburger-menu{
  z-index: 2;
  font-size: 1rem;
  margin-right: 10px;
  margin-top: 30px;
}

.navbar-bg-1 {
  transition: .6s;
  opacity: 1;
  background-color: white;
}

.menu-icon-bg {
  font-size: 1.6rem;
  margin-right: 6px; 
  color: black;
}



.cancel-icon {
  color: white;
  margin-top: -30px;
  margin-right: 5px;
  font-size: 1.5rem;
}


.cancel-icon:hover {
  color: white;
  margin-top: -30px;
  margin-right: 5px;
}

}

/* @media only screen and (max-width: 345px) {

.navbar-container {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 2;
  background: none;
  transition: .6s;
}

.show-menu {
  position: absolute;
  width: 98%;
  height: auto;
  top: 0rem;
  right: 0rem;
  background-color: black;
}

.hide-menu {
  position: absolute;
  background-color: black;
  width: 98%;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
}

.navbar-logo {
 width: 55%; 
 margin-left: 15px;
 margin-top: -1px;
}

.nav-ul {
  padding: 20px 0px;
  margin-top: 30px;
}

.nav-ul li {
  list-style: none;
  margin-bottom: 1.1rem;
  margin: 30px auto;
  font-size: 1.8rem;
  font-weight: bold;
}

.nav-ul li a {
  text-decoration: none;
  color: white;
  letter-spacing: .6px;
}

.hamburger-menu{
  z-index: 2;
  font-size: 2.2rem;
  margin-right: 10px;
  margin-top: 7px;
}

.navbar-bg {
  transition: .6s;
  opacity: 0.9;
  background-color: white;
}

.menu-icon-bg {
  font-size: 1.4rem;
  margin-right: 10px; 
  color: black;
}



.cancel-icon {
  color: white;
  margin-top: -40px;
  margin-right: 5px;
  font-size: 1.5rem;
}


.cancel-icon:hover {
  color: white;
  margin-top: -40px;
  margin-right: 5px;
}

} */
