
:root {
  --text-color-dark: #383737;
  --background-color-base: #e3c5cfe6;
}

.articlebg{
  
  justify-content: center;
  background-color: #f7f7f7;
}


.blogbox {
    width: 100%;
    margin: 2rem 1rem;
    border: 1px solid #c3bebe;
    box-shadow: 3px 2px 2px #e3c5cfe6;
    
}
.blogbox:hover {
  margin: 2rem 1rem;
  border: 2px solid #c3bebe;  
}

.blogimg {
    width: 100%;
}
.blogdesc {
    text-align: justify;
    padding: 1rem;
    font-size: .8rem;
    padding-bottom: 20%;
}
.blogbtn {
    
    border: 1px solid #e3c5cfe6;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    background-color: #b10068;
    padding: 8px 30px;
    border-radius: 9px;
    color: white;
    margin-bottom: 5rem;
    width: fit-content;
    position: absolute;
    top: 80%;
    left: 35%;
}

@media only screen and (max-width:768px) {
  .blockchaincontainer {
    width: 100%;
    display: grid;
    justify-items: center;
    padding: 1.5rem;
  }
  .blogbox {
    width: 100%;
    margin: 1rem;
    border: 1px solid #676666;
}
}

.cardimage{
  width: 100%;
}

.blockchain-content-1 {
  margin-top: 20px;
}

.blockchain-content-1-h1 {
  display: flex;
  text-align: center;
  font-size: 3.5rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
  margin-left: 43%;

}

.blockchain-content-1-h2 {
  font-size: 2rem;  
  font-weight: bold;
  margin-top: -1rem;
}

.blockchain-content-1-h6{
  color: black;
  letter-spacing: .3px;
  margin: 10px 0;
  font-size: .9rem;;
}