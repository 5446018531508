.about-mission-container {
  width: 100%;
  height: auto;
}

.about-mission-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px auto;
}

.about-mission-title {
  margin-top: 55px;
  /* margin-bottom: 50px; */
  font-size: 27px;
  font-weight: bold;
  text-align: center;
  width: 200%;
}

.about-mission-icon-class {
  width: 150px !important;
}

/* .about-mission-lottie.div{
  width: 200% ;
  height: 200%;
  margin-bottom: 80px;
}  */



.about-mission-flex {
  display: flex;
  justify-content: flex-end;
}
/* section 2 */

.about-mission-span {
  width: 50px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-mission-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 3rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
  margin-left: 85px;
}

.about-mission-content-1-h2 {
  font-size: 2rem;  
  font-weight: bold;
  margin-left: 85px;
  margin-top: -1rem;
}


.about-mission-inner {
  background-color:#f5f5f5;
  padding: 2rem 3rem;
  width: 70%;
  border-radius: 20px;
  margin: 30px auto;
}

.about-mission-p {
  margin-bottom: 0;
  font-size: 0.8rem;
}

.about-mission-2 {
  margin-bottom: 50px;
}



@media only screen and (max-width: 1000px) {
  .about-mission-container {
  width: 100%;
  height: auto;
}

.about-mission-div {
  display: flex;
  justify-content: center;
}


/* section 2 */

.about-mission-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-mission-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.5rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
  margin-left: 30px;
}

.about-mission-content-1-h2 {
  font-size: 1.6rem;  
  font-weight: bold;
  margin-left: 30px;
  margin-top: -1rem;
}


.about-mission-inner {
  background-color:#f5f5f5;
  padding: 1rem 1rem;
  width: 90%;
  border-radius: 20px;
  margin: 20px auto;
}

.about-mission-p {
  font-size: 9px;
}
}

@media only screen and (max-width: 768px) {
  .about-mission-container {
  width: 100%;
  height: auto;
}

.about-mission-div {
  display: flex;
  justify-content: center;
}

.about-mission-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px auto;
}

.about-mission-title {
  margin-top: 55px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 200%;
}

.about-mission-icon-class {
  margin-top: 10px;
  width: 100px !important;
}

}

@media only screen and (max-width: 576px) {
  .about-mission-container {
  width: 100%;
  height: auto;
}

.about-mission-div {
  display: flex;
  justify-content: center;
}

.about-mission-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px auto;
}

.about-mission-title {
  margin-top: 55px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  width: 200%;
}

.about-mission-icon-class {
  margin-top: 13px;
  width: 90px !important;
}

/* section 2 */

.about-mission-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-mission-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.2rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
  margin-left: 80px;
}

.about-mission-content-1-h2 {
  font-size: 1.2rem;  
  font-weight: bold;
  margin-left: 80px;
  margin-top: -1rem;
}


.about-mission-inner {
  background-color:#f5f5f5;
  padding: 1rem 1rem;
  width: 70%;
  border-radius: 20px;
  margin: 20px auto;
}

.about-mission-p {
  font-size: 9px;
}
}
/* 
@media only screen and (max-width: 576px) {
  .about-mission-container {
  width: 100%;
  height: auto;
}

.about-mission-div {
  display: flex;
  justify-content: center;
}

.about-mission-title {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 15px;
  font-weight: bold;
}

.about-mission-icon {
  font-size: 20px;
  margin-left: 10px;
  margin-top: 43px;
}


.about-mission-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-mission-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.2rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
  margin-left: 80px;
}

.about-mission-content-1-h2 {
  font-size: 1.2rem;  
  font-weight: bold;
  margin-left: 80px;
  margin-top: -1rem;
}


.about-mission-inner {
  background-color:#f5f5f5;
  padding: 1rem 1rem;
  width: 70%;
  border-radius: 20px;
  margin: 20px auto;
}

.about-mission-p {
  font-size: 9px;
}
} */



@media only screen and (max-width: 420px) {
  .about-mission-container {
  width: 100%;
  height: auto;
  /* margin: 40px auto; */
}


.about-mission-div {
  display: flex;
  justify-content: center;
}

.about-mission-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px auto;
}

.about-mission-title {
  margin-top: 55px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  width: 200%;
}

.about-mission-icon-class {
  margin-top: 23px;
  width: 60px !important;
}

/* section 2 */

.about-mission-span {
  width: 30px;
  height: 2px;
  background-color: #21e3ce;
  margin: auto 2px auto 0;
}

.about-mission-content-1-h1 {
  display: flex;
  justify-content: flex-start;
  font-size: 2.2rem;
  font-weight: bold;
  color: rgba(33, 227, 206, 0.18);
  margin-left: 30px;
}

.about-mission-content-1-h2 {
  font-size: 1.2rem;  
  font-weight: bold;
  margin-left: 30px;
  margin-top: -1rem;
}


.about-mission-inner {
  background-color:#f5f5f5;
  padding: 1rem 1rem;
  width: 90%;
  border-radius: 20px;
  margin: 20px auto;
}

.about-mission-p {
  font-size: 9px;
}
}