.work-home-container {
  width: 100%;
  height: 100%;
}

.work-home-img {
  filter: brightness(0.4);
  width: 100%;
  height: 100%;
}

.work-home-div-1 {
  position: relative;
}

.work-home-h1-div {
  position: absolute;
  top: 35%;
}
.work-home-h1 {
  color: white;
  text-align: center;
  font-weight: bold;
}

.work-home-span {
  color: #ff6c1a;
}

.work-home-p-div {
  position: absolute;
  bottom: 5%;
}

.work-home-p {
  font-size: 12px;
  color: white;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.work-home-p:hover {
  font-size: 12px;
  color: #ff6c1a;
  font-weight: 600;
  text-align: center;
  transition: 0.3s;
}


@media only screen and (max-width: 768px) {
  .work-home-h1 {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
}
.work-home-h1-div {
  position: absolute;
  top: 30%;
}

.work-home-p-div {
  position: absolute;
  bottom: 1%;
}


.work-home-p {
  font-size: 9px;
  color: white;
  font-weight: 600;
  text-align: center;
}

.work-home-p:hover {
  color: #ff6c1a;
  font-size: 9px;
  font-weight: 600;
  text-align: center;
}

}


@media only screen and (max-width: 576px) {
  .work-home-h1 {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}
.work-home-h1-div {
  position: absolute;
  top: 35%;
}

.work-home-p-div {
  position: absolute;
  bottom: 1%;
}


.work-home-p {
  font-size: 7px;
  color: white;
  font-weight: 600;
  text-align: center;
}

.work-home-p:hover {
  color: #ff6c1a;
  font-size: 7px;
  font-weight: 600;
  text-align: center;
}

}

@media only screen and (max-width: 400px) {
  .work-home-h1 {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
}
.work-home-h1-div {
  position: absolute;
  top: 35%;
}

.work-home-p-div {
  position: absolute;
  bottom: 0%;
}


.work-home-p {
  font-size: 5px;
  color: white;
  font-weight: 600;
  text-align: center;
}

.work-home-p:hover {
  color: #ff6c1a;
  font-size: 5px;
  font-weight: 600;
  text-align: center;
}

}