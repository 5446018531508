.work-begin-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: #ff6c1a;
}

.work-begin-content-1 {
  display: flex;
  flex-direction: column;
}

.work-begin-h6 {
  font-size: 14px;
  font-weight : 600;
  margin-bottom: 3px;
  margin-top: 50px;
  margin-left: 55px;
  color: white;
}

.work-begin-line {
  width: 3%;
  height: 1px;
  background-color: #21e3ce;
  margin-bottom: 40px;
  margin-left: 55px;
}

/* section-2  */

.work-begin-h1 {
  font-size: 25px;
  font-weight : bold;
  margin-left: 50px;
  width: 80%;
  letter-spacing: 0.5px;
  margin-bottom: 70px;
  line-height: 40px;
  color: white;
}

.work-begin-btn {
  border: 1px solid rgba(255, 108, 26, 0.7);
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 20px;
  border-radius: 9px;
  margin-left: 70px;
  margin-top: 10px;
  background-color: white;
}

.work-begin-btn:hover {
  border: 1px solid white;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 20px;
  border-radius: 9px;
  background-color: white;
  transition: 0.3s;
  box-shadow: 0 0 20px 1px white;
  transition: 0.5s;
}


@media only screen and (max-width: 796px) {
  .work-begin-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: #ff6c1a;
}

.work-begin-content-1 {
  display: flex;
  flex-direction: column;
}

.work-begin-h6 {
  font-size: 14px;
  font-weight : 600;
  margin-bottom: 3px;
  margin-top: 50px;
  margin-left: 55px;
  color: white;
}

.work-begin-line {
  width: 3%;
  height: 1px;
  background-color: #21e3ce;
  margin-bottom: 30px;
  margin-left: 55px;
}

/* section-2  */

.work-begin-h1 {
  font-size: 20px;
  font-weight : bold;
  margin-left: 50px;
  width: 80%;
  letter-spacing: 0.5px;
  margin-bottom: 70px;
  line-height: 30px;
  color: white;
}

.work-begin-btn {
  margin-left: 70px;
  margin-top: 20px;
  border: 1px solid rgba(255, 108, 26, 0.7);
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 20px;
  border-radius: 9px;
  background-color: white;
}

.work-begin-btn:hover {
  font-size: 8px;
  border: 1px solid white;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 20px;
  border-radius: 9px;
  background-color: white;
  transition: 0.3s;
  box-shadow: 0 0 20px 1px white;
  transition: 0.5s;
}
}

@media only screen and (max-width: 646px) {
  .work-begin-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: #ff6c1a;
}

.work-begin-content-1 {
  display: flex;
  flex-direction: column;
}

.work-begin-h6 {
  font-size: 14px;
  font-weight : 600;
  margin-bottom: 3px;
  margin-top: 50px;
  margin-left: 55px;
  color: white;
}

.work-begin-line {
  width: 3%;
  height: 1px;
  background-color: #21e3ce;
  margin-bottom: 30px;
  margin-left: 55px;
}

/* section-2  */

.work-begin-h1 {
  font-size: 17px;
  font-weight : bold;
  margin-left: 50px;
  width: 80%;
  letter-spacing: 0.5px;
  margin-bottom: 50px;
  line-height: 25px;
  color: white;
}

.work-begin-btn {
  border: 1px solid rgba(255, 108, 26, 0.7);
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 20px;
  border-radius: 9px;
  background-color: white;
  margin-left: 70px;
  margin-top: 20px;
}

.work-begin-btn:hover {
  border: 1px solid white;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 20px;
  border-radius: 9px;
  background-color: white;
  transition: 0.3s;
  box-shadow: 0 0 20px 1px white;
  transition: 0.5s;
}
}

@media only screen and (max-width: 576px) {
  .work-begin-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: #ff6c1a;
}

.work-begin-content-1 {
  display: flex;
  flex-direction: column;
}

.work-begin-h6 {
  font-size: 14px;
  font-weight : 600;
  margin-bottom: 3px;
  margin-top: 50px;
  margin-left: 55px;
  color: white;
}

.work-begin-line {
  width: 3%;
  height: 1px;
  background-color: #21e3ce;
  margin-bottom: 30px;
  margin-left: 55px;
}

/* section-2  */

.work-begin-h1 {
  font-size: 17px;
  font-weight : bold;
  margin-left: 50px;
  width: 80%;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  line-height: 25px;
  color: white;
}

.work-begin-btn {
  border: 1px solid rgba(255, 108, 26, 0.7);
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 20px;
  border-radius: 9px;
  background-color: white;
  margin-left: 45px;
  margin-bottom: 40px;
}

.work-begin-btn:hover {
 border: 1px solid white;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 20px;
  border-radius: 9px;
  background-color: white;
  transition: 0.3s;
  box-shadow: 0 0 20px 1px white;
  transition: 0.5s;
}
}

@media only screen and (max-width: 390px) {
  .work-begin-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: #ff6c1a;
}

.work-begin-content-1 {
  display: flex;
  flex-direction: column;
}

.work-begin-h6 {
  font-size: 14px;
  font-weight : 600;
  margin-bottom: 3px;
  margin-top: 50px;
  margin-left: 30px;
  color: white;
}

.work-begin-line {
  width: 3%;
  height: 1px;
  background-color: #21e3ce;
  margin-bottom: 20px;
  margin-left: 30px;
}

/* section-2  */

.work-begin-h1 {
  font-size: 15px;
  font-weight : bold;
  margin-left: 30px;
  width: 80%;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  line-height: 20px;
  color: white;
}

.work-begin-btn {
  border: 1px solid rgba(255, 108, 26, 0.7);
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 20px;
  border-radius: 9px;
  background-color: white;
  margin-bottom: 40px;
}

.work-begin-btn:hover {
  border: 1px solid white;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 20px;
  border-radius: 9px;
  background-color: white;
  transition: 0.3s;
  box-shadow: 0 0 20px 1px white;
  transition: 0.5s;
}
}