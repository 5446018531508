.about-begin-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: #ff6c1a;
}

.about-begin-content-1 {
  display: flex;
  flex-direction: column;
}

.about-begin-h6 {
  font-size: 14px;
  font-weight : 600;
  margin-bottom: 3px;
  margin-top: 50px;
  margin-left: 55px;
  color: white;
}

.about-begin-line {
  width: 3%;
  height: 1px;
  background-color: #21e3ce;
  margin-bottom: 40px;
  margin-left: 55px;
}

/* section-2  */

.about-begin-h1 {
  font-size: 25px;
  font-weight : bold;
  margin-left: 50px;
  width: 80%;
  letter-spacing: 0.5px;
  margin-bottom: 70px;
  line-height: 40px;
  color: white;
}

.about-begin-btn {
  border: 1px solid #ff6c1a;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 7px;
  border-radius: 9px;
  margin-left: 70px;
  margin-top: 10px;
  box-shadow: 0 15px 30px 0 rgba(20, 102, 204, 0.16);
}

/* .about-begin-btn:hover {
  border: 1px solid #ff6c1a;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: white;
  padding: 8px 7px;
  border-radius: 9px;
  background-color:#ff6c1a;
  transition: 0.3s;
} */


@media only screen and (max-width: 796px) {
  .about-begin-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: #ff6c1a;
}

.about-begin-content-1 {
  display: flex;
  flex-direction: column;
}

.about-begin-h6 {
  font-size: 14px;
  font-weight : 600;
  margin-bottom: 3px;
  margin-top: 50px;
  margin-left: 55px;
}

.about-begin-line {
  width: 3%;
  height: 1px;
  background-color: #21e3ce;
  margin-bottom: 30px;
  margin-left: 55px;
}

/* section-2  */

.about-begin-h1 {
  font-size: 20px;
  font-weight : bold;
  margin-left: 50px;
  width: 80%;
  letter-spacing: 0.5px;
  margin-bottom: 70px;
  line-height: 30px;
}

.about-begin-btn {
  border: 1px solid #ff6c1a;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 7px;
  border-radius: 9px;
  margin-left: 70px;
  margin-top: 20px;
}
/* 
.about-begin-btn:hover {
  border: 1px solid #ff6c1a;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: white;
  padding: 8px 7px;
  border-radius: 9px;
  background-color:#ff6c1a;
  transition: 0.3s;
} */
}

@media only screen and (max-width: 646px) {
  .about-begin-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: #ff6c1a;
}

.about-begin-content-1 {
  display: flex;
  flex-direction: column;
}

.about-begin-h6 {
  font-size: 14px;
  font-weight : 600;
  margin-bottom: 3px;
  margin-top: 50px;
  margin-left: 55px;
}

.about-begin-line {
  width: 3%;
  height: 1px;
  background-color: #21e3ce;
  margin-bottom: 30px;
  margin-left: 55px;
}

/* section-2  */

.about-begin-h1 {
  font-size: 17px;
  font-weight : bold;
  margin-left: 50px;
  width: 80%;
  letter-spacing: 0.5px;
  margin-bottom: 50px;
  line-height: 25px;
}

.about-begin-btn {
  border: 1px solid #ff6c1a;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 7px;
  border-radius: 9px;
  margin-left: 70px;
  margin-top: 20px;
}

/* .about-begin-btn:hover {
  border: 1px solid #ff6c1a;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: white;
  padding: 8px 7px;
  border-radius: 9px;
  background-color:#ff6c1a;
  transition: 0.3s;
} */
}

@media only screen and (max-width: 576px) {
  .about-begin-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: #ff6c1a;
}

.about-begin-content-1 {
  display: flex;
  flex-direction: column;
}

.about-begin-h6 {
  font-size: 14px;
  font-weight : 600;
  margin-bottom: 3px;
  margin-top: 50px;
  margin-left: 55px;
}

.about-begin-line {
  width: 3%;
  height: 1px;
  background-color: #21e3ce;
  margin-bottom: 30px;
  margin-left: 55px;
}

/* section-2  */

.about-begin-h1 {
  font-size: 17px;
  font-weight : bold;
  margin-left: 50px;
  width: 80%;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  line-height: 25px;
}

.about-begin-btn {
  border: 1px solid #ff6c1a;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 7px;
  border-radius: 9px;
  margin-left: 45px;
  margin-bottom: 40px;
  /* margin-top: px; */
}
/* 
.about-begin-btn:hover {
  border: 1px solid #ff6c1a;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: white;
  padding: 8px 7px;
  border-radius: 9px;
  background-color:#ff6c1a;
  transition: 0.3s;
} */
}

@media only screen and (max-width: 390px) {
  .about-begin-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: #ff6c1a;
}

.about-begin-content-1 {
  display: flex;
  flex-direction: column;
}

.about-begin-h6 {
  font-size: 14px;
  font-weight : 600;
  margin-bottom: 3px;
  margin-top: 50px;
  margin-left: 30px;
}

.about-begin-line {
  width: 3%;
  height: 1px;
  background-color: #21e3ce;
  margin-bottom: 20px;
  margin-left: 30px;
}

/* section-2  */

.about-begin-h1 {
  font-size: 15px;
  font-weight : bold;
  margin-left: 30px;
  width: 80%;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  line-height: 20px;
}

.about-begin-btn {
  border: 1px solid #ff6c1a;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: #ff6c1a;
  padding: 8px 7px;
  border-radius: 9px;
  margin-left: 30px;
  margin-bottom: 40px;
}

/* .about-begin-btn:hover {
  border: 1px solid #ff6c1a;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  color: white;
  padding: 8px 7px;
  border-radius: 9px;
  background-color:#ff6c1a;
  transition: 0.3s;
} */
}