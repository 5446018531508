.Blockchainpage {
    background-color: rgb(237, 234, 234);
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .App-header {
    margin-left: 5rem;
    margin-right: 5rem;
    padding: 3.5rem;
    background-color: white;
  }
  .content {
      line-height: 1.5;
  }
  .chead{
      margin-top: 1rem;
      font-size: 34px;
  }

  @media only screen and (max-width: 475px) {
    .chead{
      
      font-size: 30px;
  }
  }
  .ctext{
      margin-top: 1rem;
      text-align: justify;
      line-height: 2;
  }
  .cbutton {
    content: " ";
    height: auto;
    margin-top: .5rem;
    padding: .8rem;
    display: block;
    border: #e398b1e6 solid 1px;
    background-color: #e3c5cfe6;
  }
  .cbutton ::after{
      padding: 0.5rem;
      margin-top: 1rem;
      height: auto;
  }
  .cbutton ol li a {
    color: black;
  } 
  .cbutton ol li a:hover {
    transition: 2s;
  } 
  
  .btntxt {
    border: none;
    background: none;
  }
  
    li {
      display: block;
      margin-top: .5rem;
    }
    
    .linktext, .linktext1, .linktext2, .linktext3, .linktext4 , .linktext5 {
      margin-top: 1.5rem;
      text-align: justify;
      line-height: 2;  
    }
   