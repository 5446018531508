.blockChain_herosection{
    background-color: #e3c5cfe6;
   
}
.herotext{
    width: 100%;
    margin: 0%;
   
    line-height: 1.5;
    padding: 1rem 0;
    
}
.blockChain_herohead{
    margin-top: .5rem;
    margin-bottom: 1rem;
    font-size: 20px;
    line-height: 1.5;
    
    
}
.herologo {
    width: 100px!important;
    height: 50px!important;
}
.blockChain_imgLeft{
    
    margin-left: 20%;
    margin-top: 20%;
}

.blockChain_imgRight{
    width: 80%;
    margin-top: 20%;
}

@media screen and (max-width: 1150px) and (min-width: 990px) {
    
    .blockChain_imgLeft{
     
        margin-left: 20%;
        margin-top: 40%;
    }
    
    .blockChain_imgRight{
     
        margin-top: 40%;
    }
    
  }

  @media screen and (max-width:990px) {  
    .blockChain_imgLeft{
      display: none;
    }
    .blockChain_imgRight{
       width: 50%;
       margin-left: 25%;
      margin-top: 0%;
      padding-bottom: 5%;

    }
  }
  

.herourl{
    display: flex;
    justify-content: center;
    align-items: center; 
}
.herourl a {
    color: black;
    margin-left: 0.5rem;
    margin-top: 1.5rem;
}
.herourl img {
    margin-top: 1.5rem;
    height: 30px;
}

/* @media (min-width:320px) and (max-width:780px) {
    .herosection{
        display: block;
        font-size: 0.8rem;
    }
    .herologo {
        width: 15vh;
        height: 100px;
        display: block;
        margin: 0 auto;
    }
    .herohead{
        margin-top: .2rem;
        margin-bottom: 0;
        font-size: 0.8rem;
        line-height: 1.5;
    }
    .heroimg{
        width: 80%;
        padding: 0.5rem 1rem;
        margin: 0 auto;
    }
    .herourl{
        font-size: 0.6rem;
    }
    .herourl img {
        margin-top: 1.5rem;
        height: 15px;
    }
} */